import { Spinner } from "react-bootstrap";

function FullPageSpinner(props) {
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: props.height ? props.height : "80%", width: "80%", position: "fixed"}}>
      <Spinner as="span" animation="border" style={{width: "75px", height: "75px"}} />
    </div>
  );
}

export default FullPageSpinner;
