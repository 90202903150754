import React from "react";
import { ApiContext } from "./App";
import ConfirmModal from "./ConfirmModal";

class DeleteProjectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      project: undefined
    }
  }

  fillData() {
    this.setState({
      ready: true,
      project: this.props.project
    });
  }

  async onConfirm() {
    if (!this.state.project) {
      return;
    }

    this.setState({
      ready: false
    });

    const response = await ApiContext.deleteConstructionProject(this.state.project.id);

    this.props.onSuccess(response.data);
  }

  render() {
    return (
      <ConfirmModal {...this.props}
        ready={this.state.ready}
        onEnter={() => this.fillData()}
        title={`Delete Project`}
        text={`Are you sure you want to delete the construction project ${this.state.project?.name}?`}
        yesText="Yes, delete" yesVariant="danger" noText="No, cancel"
        onConfirm={() => this.onConfirm()}
        />
    );
  }
}

export default DeleteProjectModal;
