import { Stack } from "react-bootstrap";
import Heading from "./Heading";
import './App.css';
import { Building, Cpu, Gear, People } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import ReportsTable from "./ReportsTable";
import FullPageSpinner from "./FullPageSpinner";
import { ApiContext } from "./App";

function LargeItem(props) {
  const linkRef = useRef();

  return (
    <div className="large-item" onClick={() => linkRef.current.click()}>
      <Stack gap={3}>
        {props.icon && <div className="large-item-icon">{props.icon}</div>}
        <h4><Link to={props.to} ref={linkRef} onClick={e => e.stopPropagation()}>{props.title}</Link></h4>
        <p>{props.text}</p>
      </Stack>
    </div>
  );
}

function Home() {
  const [reportsRange, setReportsRange] = useState(undefined);

  useEffect(() => {
    async function fetchReportsRange() {
      if (!reportsRange) {
        const response = await ApiContext.getReportsRange();
        setReportsRange(response.data);
      }
    }
    fetchReportsRange();
  }, [reportsRange]);

  return (
    <>
      <Heading text="Dashboard Home" />
      <Stack direction="horizontal" gap={3} className="mt-3 mb-5" style={{justifyContent: "center"}}>
        <LargeItem to="/sensors" title="Sensors" text="Manage sensors and sensor groups" icon={<Cpu />} />
        <LargeItem to="/companies" title="Companies" text="Manage construction companies, and assign projects and users" icon={<Building />} />
        <LargeItem to="/projects" title="Projects" text="Manage construction projects, view reports, and view email logs" icon={<Gear />} />
        <LargeItem to="/users" title="Users" text="Manage users, and view recent email activity" icon={<People />} />
      </Stack>
      <Heading text="Recent reports" small className="mt-4" />
      {reportsRange ? <ReportsTable rowsPerPage={10} project={{first_report_time: reportsRange.first_report_time, last_report_time: reportsRange.last_report_time}} /> : <FullPageSpinner height="300px" />}
    </>
  );
}

export default Home;
