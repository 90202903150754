import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { ApiContext } from "./App";

class EditCompanyNameModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      id: undefined,
      name: "",
      nameError: undefined,
      showErrors: !this.props.creating
    }
  }

  fillData() {
    if (this.props.creating) {
      this.setState({
        id: undefined,
        name: ""
      });
    } else {
      this.setState({
        id: this.props.company.id,
        name: this.props.company.name
      });
    }
    this.setState({
      ready: true,
      nameError: undefined,
      showErrors: !this.props.creating
    });
  }

  async submit() {
    this.setState({showErrors: true});

    if (!this.state.name) {
      return false;
    }

    this.setState({ready: false, nameError: undefined});

    var response;
    if (this.props.creating) {
      response = await ApiContext.createConstructionCompany({
        name: this.state.name
      });
    } else {
      response = await ApiContext.updateConstructionCompany(this.state.id, {
        name: this.state.name
      });
    }

    this.props.onSuccess(response.data);
    return true;
  }

  cancel() {
    if (this.state.ready) {
      this.props.onCancel();
    }
  }

  render() {
    return (
      <Modal {...this.props} centered onEnter={() => this.fillData()} onHide={() => this.cancel()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.creating ? "New Company" : `Edit ${this.props.company.name}`}
          </Modal.Title>
        </Modal.Header>

        <Form autoComplete="off">
          <Modal.Body>
            <Form.Group className="mb-2" controlId="companyName">
              <Form.Label>Company name</Form.Label>
              <Form.Control type="text" disabled={!this.state.ready} autoFocus required value={this.state.name} onChange={e => this.setState({name: e.target.value})} isInvalid={this.state.showErrors && !this.state.name} />
              <Form.Control.Feedback type="invalid">
                Company name cannot be empty
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" disabled={!this.state.ready} onClick={() => this.props.onCancel()}>Discard</Button>
            <Button variant="primary" type="submit" disabled={!this.state.ready} onClick={() => this.submit()}>
              {!this.state.ready && <><Spinner as="span" animation="border" size="sm" /> </>}
              <span>{this.props.creating ? "Create" : "Save changes"}</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default EditCompanyNameModal;
