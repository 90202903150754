import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { ApiContext } from "./App";
import SearchDropdown from "./SearchDropdown";

class EditProjectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      id: undefined,
      name: "",
      code: "",
      address: "",
      city: "",
      tzName: "",
      isPilot: false,
      createSensorGroup: false,
      showErrors: !this.props.creating
    }
  }

  fillData() {
    if (this.props.creating) {
      this.setState({
        id: undefined,
        name: "",
        code: "",
        address: "",
        city: "",
        tzName: "America/Toronto",
        isPilot: false
      });
    } else {
      this.setState({
        id: this.props.project.id,
        name: this.props.project.name,
        code: this.props.project.code,
        address: this.props.project.address,
        city: this.props.project.city,
        tzName: this.props.project.tz_name,
        isPilot: this.props.project.is_pilot
      });
    }
    this.setState({
      createSensorGroup: false,
      ready: true,
      showErrors: !this.props.creating
    });
  }

  async submit() {
    this.setState({showErrors: true});

    if (!this.state.name || !this.state.tzName || (this.state.createSensorGroup && !this.state.code)) {
      return false;
    }

    this.setState({ready: false});

    const data = {
      name: this.state.name,
      code: this.state.code,
      address: this.state.address,
      city: this.state.city,
      tz_name: this.state.tzName,
      is_pilot: this.state.isPilot
    };

    var response;
    if (this.props.creating) {
      response = await ApiContext.createConstructionProject(this.state.id, data);

      if (this.state.createSensorGroup) {
        await ApiContext.createSensorGroup({
          name: this.state.code,
          construction_project_id: response.data.id,
          sensors: []
        });
      }
    } else {
      response = await ApiContext.updateConstructionProject(this.state.id, data);
    }

    this.props.onSuccess(response.data);
    return true;
  }

  cancel() {
    if (this.state.ready) {
      this.props.onCancel();
    }
  }

  async fetchTimezones() {
    const response = await ApiContext.getTimezones();
    return response.data.map(timezone => ({name: timezone, key: timezone}));;
  }

  render() {
    return (
      <Modal {...this.props} centered onEnter={() => this.fillData()} onHide={() => this.cancel()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.creating ? "New Project" : `Edit ${this.props.project.name}`}
          </Modal.Title>
        </Modal.Header>

        <Form autoComplete="off">
          <Modal.Body>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Project name</Form.Label>
              <Form.Control type="text" disabled={!this.state.ready} autoFocus required value={this.state.name} onChange={e => this.setState({name: e.target.value})} isInvalid={this.state.showErrors && !this.state.name} />
              <Form.Control.Feedback type="invalid">
                Project name cannot be empty
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="code">
              <Form.Label>Code</Form.Label>
              <Form.Control type="text" disabled={!this.state.ready} placeholder="Enter project code" value={this.state.code} onChange={e => this.setState({code: e.target.value})} isInvalid={this.state.createSensorGroup && !this.state.code} />
              <Form.Control.Feedback type="invalid">
                Project code cannot be empty when creating a sensor group
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control type="text" disabled={!this.state.ready} placeholder="Enter address" value={this.state.address} onChange={e => this.setState({address: e.target.value})} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" disabled={!this.state.ready} placeholder="Enter city" value={this.state.city} onChange={e => this.setState({city: e.target.value})} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="city">
              <Form.Label>Timezone</Form.Label>
              <SearchDropdown
                disabled={!this.state.ready}
                onChange={tzName => this.setState({tzName: tzName})}
                value={this.state.tzName}
                placeholder="Select a timezone"
                validationError={this.state.showErrors && !this.state.tzName ? "Selecting a timezone is required" : undefined}
                onUpdateItems={this.fetchTimezones} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="isPilot">
              <Form.Check type="checkbox" disabled={!this.state.ready} label="This is a pilot project" checked={this.state.isPilot} onChange={e => this.setState({isPilot: e.target.checked})} />
            </Form.Group>

            {this.props.creating &&
              <Form.Group className="mb-2" controlId="createSensorGroup">
                <Form.Check type="checkbox" disabled={!this.state.ready} label={`Also create a sensor group for this project with the same name as the project code (${this.state.code})`} checked={this.state.createSensorGroup} onChange={e => this.setState({createSensorGroup: e.target.checked})} />
              </Form.Group>
            }
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" disabled={!this.state.ready} onClick={() => this.props.onCancel()}>Discard</Button>
            <Button variant="primary" type="submit" disabled={!this.state.ready} onClick={() => this.submit()}>
              {!this.state.ready && <><Spinner as="span" animation="border" size="sm" /> </>}
              <span>{this.props.creating ? "Create project" : "Save changes"}</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default EditProjectModal;
