import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Link, useNavigate } from "react-router-dom";
import { ActivePageContext, ApiContext } from "./App";
import DataTable, { ColumnMap } from "./DataTable";

function onRowClick(row, navigate, setActivePage) {
  setActivePage({type: "email", data: row});
  navigate(`/emails/${row['id']}`);
}

function formatDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return [year, month, day].join('-');
}

function EmailsTable(props) {
  const navigate = useNavigate();
  const [, setActivePage] = React.useContext(ActivePageContext);
  const [date, setDate] = useState(props.project?.last_email_time ? new Date(props.project?.last_email_time*1000) : new Date());
  const dataTable = useRef();

  return (
    <Row>
      {!props.noCalendar &&
        <Col xs="auto">
          <Calendar
            minDate={new Date(props.project?.first_email_time*1000)}
            maxDate={new Date(props.project?.last_email_time*1000)}
            value={date}
            onChange={date => {setDate(date); dataTable.current.reload();}}
            />
        </Col>
      }
      <Col>
        <DataTable
          ref={dataTable}
          columnMap={[
            new ColumnMap(row => <Link className="clickable-cell-light" to={`/batches/${row.batch_id}`} onClick={e => e.stopPropagation()}>{row.batch_id}</Link>, "Batch ID", "20%", "batch_id"),
            new ColumnMap(row => <Link className="clickable-cell" to={`/emails/${row.id}`} onClick={e => e.stopPropagation()}>{row.recipient}</Link>, "Recipient", "25%", "recipient"),
            new ColumnMap(row => <Link className="clickable-cell-light" to={`/users/${row.user?.id}`} onClick={e => e.stopPropagation()}>{row.user?.name ? row.user?.name : row.user?.email_address}</Link>, "User", "25%", "user_id"),
            new ColumnMap(row => new Date(row.creation_time*1000).toLocaleString(), "Creation time", "25%", "creation_time"),
          ]}
          onDataNeeded={ApiContext.getEmails.bind(ApiContext)}
          onRowClick={row => onRowClick(row, navigate, setActivePage)}
          filter={{filter_construction_project_id: props.project?.id, filter_creation_time: props.noCalendar ? undefined : formatDate(date) + " %", "filter_batch_id": props.batch}}
          {...props}
          />
      </Col>
    </Row>
  );
}

export default EmailsTable;
