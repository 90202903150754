import React, { useRef, useState } from "react";
import { Badge, Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ActivePageContext, ApiContext } from "./App";
import ArrayDataProvider from "./ArrayDataProvider";
import { CompaniesTable } from "./Companies";
import DataTable, { ColumnMap } from "./DataTable";
import DeleteProjectModal from "./DeleteProjectModal";
import EditProjectCompaniesModal from "./EditProjectCompaniesModal";
import EditProjectModal from "./EditProjectModal";
import EditProjectSensorGroupsModal from "./EditProjectSensorGroupsModal";
import EditProjectUsersModal from "./EditProjectUsersModal";
import EditSensorGroupModal from "./EditSensorGroupModal";
import EmailsTable from "./EmailsTable";
import FullPageSpinner from "./FullPageSpinner";
import Heading from "./Heading";
import ReportsTable from "./ReportsTable";
import { SensorGroupsTable } from "./SensorGroups";
import { UsersTable } from "./Users";

async function fetchProject(id, setProject, setActivePage) {
  const response = await ApiContext.getConstructionProject(id);
  setProject(response.data);
  setActivePage({type: "project", data: response.data});
}

function navigateAway(navigate, setActivePage) {
  setActivePage({type: undefined, data: undefined});
  navigate("/projects");
}

function ProjectDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(undefined);
  const [editing, setEditing] = useState(false);
  const [editingCompanies, setEditingCompanies] = useState(false);
  const [editingUsers, setEditingUsers] = useState(false);
  const [editingSensorGroups, setEditingSensorGroups] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [activeTab, setActiveTab] = useState("hierarchy");
  const [, setActivePage] = React.useContext(ActivePageContext);
  
  const [isEditingSensorGroup, setIsEditingSensorGroup] = useState(false);
  const [editedSensorGroup, setEditedSensorGroup] = useState(undefined);
  const sensorGroupsTable = useRef();

  if (!project) {
    fetchProject(params.id, setProject, setActivePage);
  }

  return (
    <>
      {project ?
      <>
        <EditProjectModal show={editing} project={project} onCancel={() => setEditing(false)} onSuccess={() => setEditing(false) || setProject(undefined)} />
        <EditProjectCompaniesModal show={editingCompanies} project={project} onCancel={() => setEditingCompanies(false)} onSuccess={() => setEditingCompanies(false) || setProject(undefined)} />
        <EditProjectUsersModal show={editingUsers} project={project} onCancel={() => setEditingUsers(false)} onSuccess={() => setEditingUsers(false) || setProject(undefined)} />
        <EditProjectSensorGroupsModal show={editingSensorGroups} project={project} onCancel={() => setEditingSensorGroups(false)} onSuccess={() => setEditingSensorGroups(false) || setProject(undefined)} />
        <DeleteProjectModal show={deleting} project={project} onCancel={() => setDeleting(false)} onSuccess={() => setDeleting(false) || navigateAway(navigate, setActivePage)} />
        <Heading text={project ? `Project Details: ${project.name}` : "Project Details"}>
          <Button variant="outline-primary" onClick={() => setEditing(true)}>Edit Project</Button>
          <Button variant="outline-danger" onClick={() => setDeleting(true)}>Delete Project</Button>
        </Heading>
        <div className="details-grid">
          <Row>
            <Col md={4}>
              <div className="details-title">Code</div>
              <div>{project.code}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Address</div>
              <div>{project.address}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">City</div>
              <div>{project.city}</div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="details-title">Timezone</div>
              <div>{project.tz_name}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Is pilot</div>
              <div>{project.is_pilot ? "Yes" : "No"}</div>
            </Col>
          </Row>
        </div>
        <Tabs defaultActiveKey={activeTab} onSelect={key => setActiveTab(key)} className="mt-3">
          <Tab eventKey="hierarchy" title="Project Hierarchy">
            <Heading text="Companies" small style={{marginTop: "10px"}}>
              <Button variant="outline-primary" onClick={() => setEditingCompanies(true)}>Manage Linked Companies</Button>
            </Heading>
            <CompaniesTable rowsPerPage={10} onDataNeeded={new ArrayDataProvider(project.companies).onDataNeeded} />
            <Heading text="Users" small style={{marginTop: "10px"}}>
              <Button variant="outline-primary" onClick={() => setEditingUsers(true)}>Manage Users in Project</Button>
            </Heading>
            <UsersTable rowsPerPage={10} onDataNeeded={new ArrayDataProvider(project.users).onDataNeeded} />
          </Tab>
          <Tab eventKey="sensors" title="Sensors" className="mt-3">
            <Heading text="Sensor groups" small style={{marginTop: "10px"}}>
              <Button variant="outline-primary" onClick={() => setEditingSensorGroups(true)}>Manage Sensor Groups in Project</Button>
            </Heading>
            {editedSensorGroup &&
              <EditSensorGroupModal show={isEditingSensorGroup} sensorGroup={editedSensorGroup} onCancel={() => setIsEditingSensorGroup(false)} onSuccess={() => setIsEditingSensorGroup(false) || setProject(undefined)} />
            }
            <SensorGroupsTable
              filter={{filter_construction_project_id: project.id}}
              onRowClick={row => setEditedSensorGroup(row) || setIsEditingSensorGroup(true)}
              ref={sensorGroupsTable}
              rowsPerPage={10} compactLoading
              />
            <Heading text="Scheduled reports based on linked sensor groups" small style={{marginTop: "10px"}} />
            <DataTable
              columnMap={[
                new ColumnMap(row => <a className="clickable-cell">{row.report_type}</a>, "Report Type", "20%", "report_type"),
                new ColumnMap(row => row.trigger.name, "Trigger", "20%", "trigger"),
                new ColumnMap(row => <>{row.sensors.map(sensor => <><Badge bg="secondary" size="sm">{sensor.name}</Badge>{" "}</>)}</>, "Sensors", "60%", undefined, {whiteSpace: "nowrap"}),
              ]}
              onDataNeeded={new ArrayDataProvider(project.report_templates).onDataNeeded}
              rowsPerPage={10} compactLoading
              />
          </Tab>
          <Tab eventKey="reports" title="Reports" className="mt-3">
            <ReportsTable rowsPerPage={10} project={project} />
          </Tab>
          <Tab eventKey="emails" title="Emails" className="mt-3">
            <EmailsTable rowsPerPage={10} project={project} />
          </Tab>
        </Tabs>
      </>
      :
      <FullPageSpinner />
      }
    </>
  );
}

export default ProjectDetails;
