import Heading from "./Heading";
import { Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable, { ColumnMap } from "./DataTable";
import { ActivePageContext, ApiContext } from "./App";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import EditProjectModal from "./EditProjectModal";

function onRowClick(row, navigate, setActivePage) {
  setActivePage({type: "project", data: row});
  navigate(`/projects/${row['id']}`);
}

export function ProjectsTable(props) {
  const navigate = useNavigate();
  const [, setActivePage] = React.useContext(ActivePageContext);

  return (
    <DataTable
      columnMap={[
        new ColumnMap(row => row.code, "Code", "15%", "code"),
        new ColumnMap(row => <Link className="clickable-cell" to={`/projects/${row.id}`} onClick={e => e.stopPropagation()}>{row.name}</Link>, "Name", "35%", "name"),
        new ColumnMap(row => <>{row.companies?.map(company => <><Badge bg="secondary" size="sm">{company.name}</Badge>{" "}</>)}</>, "Companies", "35%", undefined, {whiteSpace: "nowrap"}),
        new ColumnMap(row => row.city, "City", "15%", "city"),
      ]}
      onDataNeeded={ApiContext.getConstructionProjects.bind(ApiContext)}
      onRowClick={row => onRowClick(row, navigate, setActivePage)}
      {...props}
      />
  );
}

function Projects(props) {
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);
  const [, setActivePage] = React.useContext(ActivePageContext);

  return (
    <>
      <EditProjectModal show={isCreating} creating onCancel={() => setIsCreating(false)} onSuccess={project => {setIsCreating(false); onRowClick(project, navigate, setActivePage);}} />
      <Heading text="Construction Projects">
        <Button variant="outline-primary" onClick={() => setIsCreating(true)}>New Project</Button>
      </Heading>
      <ProjectsTable />
    </>
  );
}

export default Projects;
