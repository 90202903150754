import React, { useRef, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { Pencil } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { ApiContext } from "./App";
import DataTable, { ColumnMap } from "./DataTable";
import EditSensorGroupModal from "./EditSensorGroupModal";
import Heading from "./Heading";

export const SensorGroupsTable = React.forwardRef((props, ref) => {
  return (
    <DataTable
      columnMap={[
        new ColumnMap(row => <a className="clickable-cell">{row.name}</a>, "Name", "25%", "name"),
        new ColumnMap(row => row.construction_project ? <Link className="clickable-cell-light" to={`/projects/${row.construction_project.id}`} onClick={e => e.stopPropagation()}>
          {row.construction_project.name}
        </Link> : "Not in a project", "Construction Project", "30%", "construction_project_id"),
        new ColumnMap(row => <>{row.sensors.map(sensor => <><Badge bg="secondary" size="sm">{sensor.name}</Badge>{" "}</>)}</>, "Sensors", "40%", undefined, {whiteSpace: "nowrap"}),
        new ColumnMap(row => <Pencil title="Edit" />, "", "5%", undefined, {textAlign: "right"}),
      ]}
      onDataNeeded={ApiContext.getSensorGroups.bind(ApiContext)}
      ref={ref}
      {...props}
      />
  );
});

function SensorGroups(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [editedSensorGroup, setEditedSensorGroup] = useState(undefined);
  const dataTable = useRef();

  return (
    <>
      <Heading text="Sensor Groups">
        <Button variant="outline-primary" onClick={() => setIsCreating(true)}>New Sensor Group</Button>
      </Heading>
      {editedSensorGroup &&
        <EditSensorGroupModal show={isEditing} sensorGroup={editedSensorGroup} onCancel={() => setIsEditing(false)} onSuccess={() => setIsEditing(false) || dataTable.current.reload()} />
      }
      <EditSensorGroupModal show={isCreating} creating onCancel={() => setIsCreating(false)} onSuccess={() => setIsCreating(false) || dataTable.current.reload()} />
      <SensorGroupsTable
        ref={dataTable}
        onRowClick={row => setEditedSensorGroup(row) || setIsEditing(true)}
        />
    </>
  );
}

export default SensorGroups;
