import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { ApiContext } from "./App";
import { encode } from "uint8-to-base64";

class UploadCompanyLogoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      id: undefined,
      file: undefined,
      fileName: undefined,
      nameError: undefined,
      showErrors: false
    }
  }

  fillData() {
    this.setState({
      id: this.props.company.id,
      file: undefined,
      fileName: undefined,
      ready: true,
      nameError: undefined,
      showErrors: false
    });
  }

  async submit() {
    this.setState({showErrors: true});

    if (!this.state.file) {
      return false;
    }

    const contents = await this.state.file.arrayBuffer();
    const encoded = encode(new Uint8Array(contents));

    this.setState({ready: false, nameError: undefined});

    const response = await ApiContext.uploadConstructionCompanyLogo(this.state.id, {
      mimetype: this.state.file.type,
      data: encoded
    });

    this.props.onSuccess(response.data);
    return true;
  }

  cancel() {
    if (this.state.ready) {
      this.props.onCancel();
    }
  }

  render() {
    return (
      <Modal {...this.props} centered onEnter={() => this.fillData()} onHide={() => this.cancel()}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit {this.props.company.name}
          </Modal.Title>
        </Modal.Header>

        <Form autoComplete="off">
          <Modal.Body>
            <Form.Group className="mb-2" controlId="companyName">
              <Form.Label>Logo file</Form.Label>
              <Form.Control type="file" accept="image/*" disabled={!this.state.ready} autoFocus required value={this.state.fileName} onChange={e => this.setState({file: e.target.files[0], fileName: e.target.value})} isInvalid={this.state.showErrors && !this.state.file} />
              <Form.Control.Feedback type="invalid">
                Please select a file
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" disabled={!this.state.ready} onClick={() => this.props.onCancel()}>Discard</Button>
            <Button variant="primary" type="submit" disabled={!this.state.ready} onClick={e => {e.preventDefault(); this.submit();}}>
              {!this.state.ready && <><Spinner as="span" animation="border" size="sm" /> </>}
              <span>Upload logo</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default UploadCompanyLogoModal;
