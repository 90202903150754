import './App.css';
// @ts-nocheck

import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from './sidebar/Sidebar';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import React, { useContext, useEffect, useState ,useRef} from 'react'
import LoginModal from './LoginModal';
import Api from './Api';
import Home from './Home';
import Sensors from './Sensors';
import SensorDetails from './SensorDetails';
import SensorGroups from './SensorGroups';
import EventLog from './EventLog';
import Projects from './Projects';
import Companies from './Companies';
import CompanyDetails from './CompanyDetails';
import Users from './Users';
import ProjectDetails from './ProjectDetails';
import UserDetails from './UserDetails';
import ReportDetails from './ReportDetails';
import EmailDetails from './EmailDetails';
import BatchDetails from './BatchDetails';
import SystemUsers from './SystemUsers';
// import ApiUrl from './ApiUrl';

export const UserContext = React.createContext(null);
export const ActivePageContext = React.createContext(null);
// console.log(location.href);
// console.log(location.origin);
// console.log(location.pathname);
// export const ApiContext = new Api(ApiUrl);
// const location = useLocation()
// export const ApiContext = new Api(location.origin+":8383");


export let ApiContext = null;

function loadSavedUser() {
  if (localStorage.getItem("token") == null) {
    return null;
  }

  return {
    id: localStorage.getItem("id"),
    name: localStorage.getItem("name"),
    token: localStorage.getItem("token"),
    expiry: localStorage.getItem("expiry")
  }
}

function saveUser(user) {
  if (user) {
    localStorage.setItem("id", user.id);
    localStorage.setItem("name", user.name);
    localStorage.setItem("token", user.token);
    localStorage.setItem("expiry", user.expiry);
  } else {
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    localStorage.removeItem("expiry");
  }
}

async function refreshToken(currentUser, setUser) {
  if (!currentUser || !currentUser.expiry) {
    return;
  }

  const expiry = Number(currentUser.expiry);
  const now = new Date().getTime() / 1000;

  if (!expiry || now > expiry) {
    // Token has already expired
    setUser(null);
  } else if (expiry - now <= 60*60) {
    // Refresh token
    const response = await ApiContext.renewToken();
    if (response.error) {
      setUser(null);
    } else {
      setUser({
        ...currentUser,
        token: response.token,
        expiry: response.expiry_time
      });
    }
  }
}

function App() {
  const [user, setUser] = React.useState(loadSavedUser());
  refreshToken(user, setUser);
  saveUser(user);

  useLocation();
  // const location = useLocation();
  // console.log(location);
  // console.log(location.href);
  // console.log(location.origin);
  // console.log(location.pathname);
  let hrefOrigin = useRef();

  useEffect( () => {  
      hrefOrigin.current= window.location.origin;
      console.log(window.location);
      console.log(window.location.protocol);
      console.log(window.location.hostname);
      const APIURL=window.location.protocol+"//"+window.location.hostname+":8383/rest"
      console.log("APIURL: "+APIURL);
      ApiContext = new Api(APIURL);
      // Object.freeze(ApiContext);
  }, []);
  
  const [activePage, setActivePage] = React.useState({ type: undefined, data: undefined });

  return (
    <UserContext.Provider value={[user, setUser]}>
      <ActivePageContext.Provider value={[activePage, setActivePage]}>
        <Container fluid>
          <Row>
            <Col xs={2} className='bg-dark text-white position-fixed h-100 py-4'>
              <Sidebar />
            </Col>
            <Col xs={{span: 10, offset: 2}} >
              <Container fluid>
                {user &&
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/sensors" element={<Sensors />} />
                    <Route path="/sensors/:id" element={<SensorDetails />} />
                    <Route path="/sensor-groups" element={<SensorGroups />} />
                    <Route path="/companies" element={<Companies />} />
                    <Route path="/companies/:id" element={<CompanyDetails />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/projects/:id" element={<ProjectDetails />} />
                    <Route path="/reports/:id" element={<ReportDetails />} />
                    <Route path="/emails/:id" element={<EmailDetails />} />
                    <Route path="/batches/:id" element={<BatchDetails />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/:id" element={<UserDetails />} />
                    <Route path="/system-users" element={<SystemUsers />} />
                    <Route path="/log" element={<EventLog />} />
                  </Routes>
                }
              </Container>
            </Col>
          </Row>
        </Container>
      </ActivePageContext.Provider>

      <LoginModal show={user == null} onSuccess={(user) => setUser(user)} />
    </UserContext.Provider>
  );
}

function AppRouter() {
  return <Router><App /></Router>;
}

export default AppRouter;
