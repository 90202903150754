import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ApiContext } from "./App";
import DataTable, { ColumnMap } from "./DataTable";
import Heading from "./Heading";

function EventLog() {
  const [event, setEvent] = useState(undefined);
  const [isEventShown, setIsEventShown] = useState(undefined);

  return (
    <>
      <Heading text="Event Log" />

      <Modal show={isEventShown} onHide={() => setIsEventShown(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Event Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre style={{height: "350px", overflowY: "auto", whiteSpace: "pre-wrap", marginBottom: 0}}>{event?.message}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button className="w-100" variant="secondary" onClick={() => setIsEventShown(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <DataTable
        columnMap={[
          new ColumnMap(row => new Date(row.time*1000).toLocaleString(), "Time", "15%", "time"),
          new ColumnMap(row => row.event_type, "Event Type", "10%", "event_type"),
          new ColumnMap(row => <a className="clickable-cell-light">{row.message}</a>, "Message", "65%", undefined, {whiteSpace: "nowrap"}),
          new ColumnMap(row => row.origin_ip, "Origin IP", "10%"),
        ]}
        onDataNeeded={ApiContext.getEventLog.bind(ApiContext)}
        onRowClick={row => {setEvent(row); setIsEventShown(true);}}
        defaultOrder="time" defaultOrderDesc
        />
    </>
  );
}

export default EventLog;
