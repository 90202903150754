import React, { useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ActivePageContext, ApiContext } from "./App";
import ArrayDataProvider from "./ArrayDataProvider";
import EmailsTable from "./EmailsTable";
import FullPageSpinner from "./FullPageSpinner";
import Heading from "./Heading";

async function fetchReport(id, setReport, setActivePage) {
  const response = await ApiContext.getReport(id);
  setReport(response.data);
  setActivePage({type: "report", data: response.data});
}

async function downloadReportFile(report, setStatus) {
  setStatus(true);

  try {
    const response = await ApiContext.getReportFile(report.id);
    const url = URL.createObjectURL(response);

    // Use a hidden link to trick the browser into downloading the file with the right filename
    const element = document.createElement("a");
    element.href = url;
    element.style.display = "none";
    element.download = report.file;
    element.target = "_blank";
    element.click();

    URL.revokeObjectURL(url);
  } finally {
    setStatus(false);
  }
}

function ReportDetails() {
  const params = useParams();
  const [report, setReport] = useState(undefined);
  const [downloadStatus, setDownloadStatus] = useState(false);
  const [, setActivePage] = React.useContext(ActivePageContext);

  if (!report) {
    fetchReport(params.id, setReport, setActivePage);
  }

  return (
    <>
      {report ?
      <>
        <Heading text="Report Details">
          <Button variant="outline-primary" disabled={downloadStatus} onClick={() => downloadReportFile(report, setDownloadStatus)}>
            {downloadStatus && <><Spinner as="span" animation="border" size="sm" /> </>}
            <span>Download Report</span>
          </Button>
        </Heading>
        <div className="details-grid">
          <h4>{report.file}</h4>
          <Row>
            <Col md={4}>
              <div className="details-title">Trigger</div>
              <div>{report.trigger.name}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Type</div>
              <div>{report.type}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Construction project</div>
              <div><Link className="clickable-cell-light" style={{textDecoration: "underline"}} to={`/projects/${report.construction_project.id}`}>{report.construction_project.name}</Link></div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="details-title">Creation time</div>
              <div>{new Date(report.creation_time*1000).toLocaleString()}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Start time</div>
              <div>{new Date(report.start_time*1000).toLocaleString()}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">End time</div>
              <div>{new Date(report.end_time*1000).toLocaleString()}</div>
            </Col>
          </Row>
        </div>
        <Heading text="Included in emails" small style={{marginTop: "10px"}} />
        <EmailsTable noCalendar rowsPerPage={10} onDataNeeded={new ArrayDataProvider(report.emails).onDataNeeded} />
      </>
      :
      <FullPageSpinner />
      }
    </>
  );
}

export default ReportDetails;
