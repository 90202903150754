import { Button, Modal, Spinner } from "react-bootstrap";

function ConfirmModal(props) {
  return (
    <Modal {...props} centered onHide={() => props.onCancel()}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.text}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" disabled={!props.ready} onClick={() => props.onCancel()}>{props.noText}</Button>
        <Button variant={props.yesVariant} disabled={!props.ready} onClick={() => props.onConfirm(props)}>
          {!props.ready && <><Spinner as="span" animation="border" size="sm" /> </>}
          <span>{props.yesText}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
