import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { ApiContext } from "./App";
import DeleteSystemUserModal from "./DeleteSystemUserModal";

class EditSystemUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      id: undefined,
      email: "",
      name: "",
      password: "",
      showErrors: !this.props.creating,
      isDeleting: false,
      loading: false
    }
  }

  fillData() {
    if (this.props.creating) {
      this.setState({
        id: undefined,
        email: "",
        name: "",
        password: "",
        loading: false
      });
    } else {
      if (!this.props.user.email) {
        this.setState({
          id: this.props.user.id,
          email: "",
          name: "",
          password: ""
        });
        this.fetchData();
      } else {
        this.setState({
          id: this.props.user.id,
          email: this.props.user.email,
          name: this.props.user.name,
          password: "",
          loading: false
        });
      }
    }
    this.setState({
      ready: true,
      showErrors: !this.props.creating
    });
  }

  async fetchData() {
    this.setState({
      ready: false,
      loading: true
    });

    const response = await ApiContext.getSystemUser(this.props.user.id);
    
    this.setState({
      ready: true,
      loading: false,
      response: response.data,
      id: this.props.user.id,
      email: response.data.email,
      name: response.data.name,
      password: ""
    });
  }

  async submit() {
    this.setState({showErrors: true});

    if (!this.state.email || (this.props.creating && !this.state.password)) {
      return false;
    }

    this.setState({ready: false});

    const data = {
      email: this.state.email,
      name: this.state.name
    };
    if (this.state.password) {
      data.password = this.state.password;
    }

    var response;
    if (this.props.creating) {
      response = await ApiContext.createSystemUser(data);
    } else {
      response = await ApiContext.updateSystemUser(this.state.id, data);
    }

    this.props.onSuccess(response.data);
    return true;
  }

  cancel() {
    if (this.state.ready) {
      this.setState({
        isDeleting: false
      });
      this.props.onCancel();
    }
  }

  onDeleteSuccess(data) {
    this.setState({
      isDeleting: false
    });
    this.props.onSuccess(data);
  }

  render() {
    return (
      <>
        <DeleteSystemUserModal
          show={this.props.show && this.state.isDeleting}
          user={this.props.user}
          onCancel={() => this.cancel()}
          onSuccess={data => this.onDeleteSuccess(data)}
        />

        <Modal {...this.props} centered onEnter={() => this.fillData()} onHide={() => this.cancel()} show={this.props.show && !this.state.isDeleting}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.creating ? <>New System User</> : <>Edit {this.props.user.email ? this.props.user.email : this.state.response?.email ? this.state.response?.email : "System User"}</>}
            </Modal.Title>
          </Modal.Header>

          {
            this.state.loading ?
            <Modal.Body style={{textAlign: "center"}}>
              <Spinner animation="border" size="lg" style={{width: "75px", height: "75px", marginTop: "100px", marginBottom: "100px"}} as="span" />
            </Modal.Body>
            :
            <Form autoComplete="off">
              <Modal.Body>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="text" disabled={!this.state.ready || !this.props.creating} autoFocus required value={this.state.email} onChange={e => this.setState({email: e.target.value})} isInvalid={this.state.showErrors && !this.state.email} />
                  <Form.Control.Feedback type="invalid">
                    Email address cannot be empty
                  </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder={this.props.creating ? undefined : "(unchanged)"} disabled={!this.state.ready} required={this.props.creating} value={this.state.password} onChange={e => this.setState({password: e.target.value})} />
                </Form.Group>
                
                <Form.Group className="mb-2" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" disabled={!this.state.ready} value={this.state.name} onChange={e => this.setState({name: e.target.value})} />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                {!this.props.creating &&
                  <Button variant="danger" disabled={!this.state.ready || this.state.id === this.props.loggedInUser?.id} onClick={() => this.setState({isDeleting: true})} style={{marginRight: "auto"}}>
                    {this.state.id === this.props.loggedInUser?.id ? "Can't delete own user" : "Delete system user"}
                  </Button>
                }
                <Button variant="secondary" disabled={!this.state.ready} onClick={() => this.props.onCancel()}>Discard</Button>
                <Button variant="primary" type="submit" disabled={!this.state.ready} onClick={() => this.submit()}>
                  {!this.state.ready && <><Spinner as="span" animation="border" size="sm" /> </>}
                  <span>{this.props.creating ? "Create system user" : "Save changes"}</span>
                </Button>
              </Modal.Footer>
            </Form>
          }
        </Modal>
      </>
    );
  }
}

export default EditSystemUserModal;
