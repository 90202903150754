import { Row, Col } from "react-bootstrap";

function Heading(props) {
  return (
    <Row className="heading-bar" {...props}>
      <Col>
        {props.small ? <h4>{props.text}</h4> : <h3>{props.text}</h3>}
      </Col>
      <Col xs="auto">
        {props.children}
      </Col>
      {!props.small && <hr />}
    </Row>
  );
}

export default Heading;
