import Heading from "./Heading";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable, { ColumnMap } from "./DataTable";
import { ActivePageContext, ApiContext } from "./App";
import { useNavigate } from "react-router-dom";
import React, { useRef, useState } from "react";
import EditSensorGroupModal from "./EditSensorGroupModal";

function onRowClick(row, navigate, setActivePage) {
  setActivePage({type: "sensor", data: row});
  navigate(`/sensors/${row['id']}`);
}

function Sensors(props) {
  const navigate = useNavigate();
  const [, setActivePage] = React.useContext(ActivePageContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editedSensorGroup, setEditedSensorGroup] = useState(undefined);
  const dataTable = useRef();

  return (
    <>
      <Heading text="Sensors">
        <Link to="/sensor-groups">
          <Button variant="outline-primary">Manage Sensor Groups</Button>
        </Link>
      </Heading>
      {editedSensorGroup &&
        <EditSensorGroupModal show={isEditing} sensorGroup={editedSensorGroup} onCancel={() => setIsEditing(false)} onSuccess={() => setIsEditing(false) || dataTable.current.reload()} />
      }
      <DataTable
        ref={dataTable}
        columnMap={[
          new ColumnMap(row => row.vendor_id, "ID", "10%", "vendor_id"),
          new ColumnMap(row => row.type, "Type", "15%", "type"),
          new ColumnMap(row => <Link className="clickable-cell" to={`/sensors/${row["id"]}`} onClick={e => e.stopPropagation()}>{row.name}</Link>, "Name", "30%", "name"),
          new ColumnMap(row => row.location, "Location", "30%", "location"),
          new ColumnMap(row => row.sensor_group ? <>
            <OverlayTrigger placement="right" overlay={
              <Tooltip>{row["sensor_group"]["construction_project"] ? <>In project {row.sensor_group.construction_project.name}</> : <>Not in a project</>}</Tooltip>
            }>
              <a className="clickable-cell-light" onClick={e => {e.stopPropagation(); setIsEditing(true); setEditedSensorGroup(row.sensor_group);}}>{row.sensor_group.name}</a>
            </OverlayTrigger>
          </> : "", "Sensor Group", "15%", "sensor_group_id"),
          // new ColumnMap(row => <Pencil title="Edit" onClick={e => e.stopPropagation() || alert("edit " + row['name'])} />, "", "5%", undefined, {textAlign: "right"}),
        ]}
        onDataNeeded={ApiContext.getSensors.bind(ApiContext)}
        onRowClick={row => onRowClick(row, navigate, setActivePage)}
        />
    </>
  );
}

export default Sensors;
