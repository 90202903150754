import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Link, useNavigate } from "react-router-dom";
import { ActivePageContext, ApiContext } from "./App";
import DataTable, { ColumnMap } from "./DataTable";

function onRowClick(row, navigate, setActivePage) {
  setActivePage({type: "report", data: row});
  navigate(`/reports/${row['id']}`);
}

function formatDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return [year, month, day].join('-');
}

function ReportsTable(props) {
  const navigate = useNavigate();
  const [, setActivePage] = React.useContext(ActivePageContext);
  const [date, setDate] = useState(props.project?.last_report_time ? new Date(props.project.last_report_time*1000) : new Date());
  const dataTable = useRef();

  return (
    <Row>
      {!props.noCalendar &&
        <Col xs="auto">
          <Calendar
            minDate={new Date(props.project?.first_report_time*1000)}
            maxDate={new Date(props.project?.last_report_time*1000)}
            value={date}
            onChange={date => {setDate(date); dataTable.current.reload();}}
            />
        </Col>
      }
      <Col>
        <DataTable
          ref={dataTable}
          columnMap={[
            new ColumnMap(row => row.trigger.name, "Trigger", "10%", "trigger"),
            new ColumnMap(row => row.type, "Type", "15%", "type"),
            new ColumnMap(row => <Link className="clickable-cell" to={`/reports/${row.id}`} onClick={e => e.stopPropagation()}>{row.file}</Link>, "Name", "35%", "file"),
            new ColumnMap(row => new Date(row.start_time*1000).toLocaleString(), "Start time", "20%", "start_time"),
            new ColumnMap(row => new Date(row.end_time*1000).toLocaleString(), "End time", "20%", "end_time"),
          ]}
          onDataNeeded={ApiContext.getReports.bind(ApiContext)}
          onRowClick={row => onRowClick(row, navigate, setActivePage)}
          filter={{filter_construction_project_id: props.project?.id, filter_start_time: props.noCalendar ? undefined : formatDate(date) + " %"}}
          {...props}
          />
      </Col>
    </Row>
  );
}

export default ReportsTable;
