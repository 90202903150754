import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { ApiContext } from "./App";
import CollectionEditor from "./CollectionEditor";

class EditProjectCompaniesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      id: undefined,
      companies: []
    }
  }

  async fetchCompanies() {
    const response = await ApiContext.getConstructionCompanyNames();
    return response.data.map(company => ({name: company.name, key: company}));
  }

  fillData() {
    this.setState({
      id: this.props.project.id,
      companies: this.props.project.companies,
      ready: true
    });
  }

  async submit() {
    this.setState({ready: false});

    const response = await ApiContext.updateConstructionProject(this.state.id, {
      companies: this.state.companies.map(company => company.id)
    });

    this.props.onSuccess(response.data);
    return true;
  }

  cancel() {
    if (this.state.ready) {
      this.props.onCancel();
    }
  }

  render() {
    return (
      <Modal {...this.props} centered onEnter={() => this.fillData()} onHide={() => this.cancel()}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit {this.props.project.name}
          </Modal.Title>
        </Modal.Header>

        <Form autoComplete="off">
          <Modal.Body>
            <Form.Group className="mb-2" controlId="companyName">
              <Form.Label>Companies linked with project</Form.Label>
              <CollectionEditor
                disabled={!this.state.ready}
                dropdownPlaceholder="Add company"
                itemsPlaceholder="No companies linked with this project."
                items={this.state.companies}
                onUpdateItems={companies => this.setState({companies: companies})}
                onFetchItems={() => this.fetchCompanies()}
                />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" disabled={!this.state.ready} onClick={() => this.props.onCancel()}>Discard</Button>
            <Button variant="primary" type="submit" disabled={!this.state.ready} onClick={() => this.submit()}>
              {!this.state.ready && <><Spinner as="span" animation="border" size="sm" /> </>}
              <span>Save changes</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default EditProjectCompaniesModal;
