import React, { useState } from "react";
import { Button, Col, Row, Tabs, Tab } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ActivePageContext, ApiContext } from "./App";
import ArrayDataProvider from "./ArrayDataProvider";
import { EventsTable } from "./BatchDetails";
import DeleteUserModal from "./DeleteUserModal";
import EditUserModal from "./EditUserModal";
import EditUserProjectsModal from "./EditUserProjects";
import FullPageSpinner from "./FullPageSpinner";
import Heading from "./Heading";
import { ProjectsTable } from "./Projects";

async function fetchUser(id, setUser, setActivePage) {
  const response = await ApiContext.getUser(id);
  setUser(response.data);
  setActivePage({type: "user", data: response.data});
}

function navigateAway(navigate, setActivePage) {
  setActivePage({type: undefined, data: undefined});
  navigate("/users");
}

function UserDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(undefined);
  const [editing, setEditing] = useState(false);
  const [editingProjects, setEditingProjects] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [, setActivePage] = React.useContext(ActivePageContext);

  if (!user) {
    fetchUser(params.id, setUser, setActivePage);
  }

  return (
    <>
      {user ?
      <>
        <EditUserModal show={editing} user={user} onCancel={() => setEditing(false)} onSuccess={() => setEditing(false) || setUser(undefined)} />
        <EditUserProjectsModal show={editingProjects} user={user} onCancel={() => setEditingProjects(false)} onSuccess={() => setEditingProjects(false) || setUser(undefined)} />
        <DeleteUserModal show={deleting} user={user} onCancel={() => setDeleting(false)} onSuccess={() => setDeleting(false) || navigateAway(navigate, setActivePage)} />
        <Heading text={user ? `User Details: ${user.name}` : "User Details"}>
          <Button variant="outline-primary" onClick={() => setEditing(true)}>Edit User</Button>
          <Button variant="outline-danger" onClick={() => setDeleting(true)}>Delete User</Button>
        </Heading>
        <div className="details-grid">
          <Row>
            <Col md={4}>
              <div className="details-title">Name</div>
              <div>{user.name}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Title</div>
              <div>{user.title}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Email address</div>
              <div>{user.email_address}</div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="details-title">Subscribed to emails</div>
              <div>{user.email_preference ? "Yes" : "No"}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Construction company</div>
              <div>
                {user.construction_company ?
                <>
                  <Link className="clickable-cell-light" style={{textDecoration: "underline"}} to={`/companies/${user.construction_company.id}`}>{user.construction_company.name}</Link>
                </>
                :
                <>Not in a company</>
                }
              </div>
            </Col>
          </Row>
        </div>
        <Tabs defaultActiveKey="hierarchy" className="mt-3">
          <Tab eventKey="hierarchy" title="Project Hierarchy">
            <Heading text="Projects" small style={{marginTop: "10px"}}>
              <Button variant="outline-primary" onClick={() => setEditingProjects(true)}>Manage Linked Projects</Button>
            </Heading>
            <ProjectsTable rowsPerPage={10} onDataNeeded={new ArrayDataProvider(user.construction_projects).onDataNeeded} />
          </Tab>
          <Tab eventKey="events" title="Email Activity" className="mt-2">
            <EventsTable rowsPerPage={10} filter={{"filter_NotificationEmail.user_id": user.id}} showBatchId />
          </Tab>
        </Tabs>
      </>
      :
      <FullPageSpinner />
      }
    </>
  );
}

export default UserDetails;
