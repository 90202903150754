import React, { useState } from "react";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ActivePageContext, ApiContext } from "./App";
import EditSensorGroupModal from "./EditSensorGroupModal";
import EditSensorModal from "./EditSensorModal";
import FullPageSpinner from "./FullPageSpinner";
import Heading from "./Heading";

async function fetchSensor(id, setSensor, setActivePage) {
  const response = await ApiContext.getSensor(id);
  setSensor(response.data);
  setActivePage({type: "sensor", data: response.data});
}

function SensorDetails() {
  const params = useParams();
  const [sensor, setSensor] = useState(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingSensorGroup, setIsEditingSensorGroup] = useState(false);
  const [, setActivePage] = React.useContext(ActivePageContext);

  if (!sensor) {
    fetchSensor(params.id, setSensor, setActivePage);
  }

  return (
    <>
      <Heading text="Sensor Details">
        <Button variant="outline-primary" onClick={() => setIsEditing(true)}>Edit Sensor</Button>
      </Heading>
      {sensor ? 
      <>
        <EditSensorModal show={isEditing} sensor={sensor} onCancel={() => setIsEditing(false)} onSuccess={() => setIsEditing(false) || setSensor(undefined)} />
        {sensor.sensor_group &&
          <EditSensorGroupModal show={isEditingSensorGroup} sensorGroup={sensor.sensor_group} onCancel={() => setIsEditingSensorGroup(false)} onSuccess={() => setIsEditingSensorGroup(false) || setSensor(undefined)} />
        }
        <div className="details-grid">
          <h4>{sensor.name}</h4>
          <Row>
            <Col md={4}>
              <div className="details-title">Type</div>
              <div>{sensor.type}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Code</div>
              <div>{sensor.code}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Vendor</div>
              <div>{sensor.vendor}</div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="details-title">ID</div>
              <div>{sensor.vendor_id}{sensor.vendor_friendly_id && ` (${sensor.vendor_friendly_id})`}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Location</div>
              <div>{sensor.location}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Suppress summary</div>
              <div>{sensor.suppress_summary ? "Yes" : "No"}</div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="details-title">Datasets</div>
              <div>{sensor.datasets.map(dataset => <span key={dataset}><Badge bg="secondary">{dataset}</Badge>{" "}</span>)}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Sensor group</div>
              <div>
                {sensor.sensor_group ?
                <>
                  <a href="#" className="clickable-cell-light" style={{textDecoration: "underline"}} onClick={() => setIsEditingSensorGroup(true)}>
                    {sensor.sensor_group.name} ({sensor.sensor_group.construction_project ? <>In project {sensor.sensor_group.construction_project.name}</> : <>Not in a project</>})
                  </a>
                </>
                :
                <>Not in a sensor group</>
                }
              </div>
            </Col>
          </Row>
        </div>
      </>
      :
      <FullPageSpinner />
      }
    </>
  ); 
}

export default SensorDetails;
