import { useState } from "react";
import { Dropdown, Form, Spinner } from "react-bootstrap";

function getFilteredDropdownItems(filter, listItems, props) {
  if (!filter) {
    filter = "";
  }
  return listItems?.filter(item => item.name.toLowerCase().startsWith(filter.toLowerCase())).map(
    item => <Dropdown.Item key={item.name} onClick={() => {console.log(item); props.onChange && props.onChange(item.name); props.onSelect && props.onSelect(item.key);}}><b>{item.name.substr(0, filter.length)}</b>{item.name.substr(filter.length)}</Dropdown.Item>
  );
}

async function fetchItemsAsync(onUpdateItems, setListItems) {
  const items = await onUpdateItems();
  setListItems(items);
}

function SearchDropdown(props) {
  const [listItems, setListItems] = useState(undefined);
  const [fetchCalled, setFetchCalled] = useState(false);

  if (!fetchCalled && listItems === undefined) {
    setFetchCalled(true);
    fetchItemsAsync(props.onUpdateItems, setListItems);
  }

  return (
    <Dropdown disabled={props.disabled}>
      <Dropdown.Toggle as={Form.Control} disabled={props.disabled} isInvalid={props.validationError} type="text" className="form-select" placeholder={props.placeholder} value={props.value} onChange={e => props.onChange(e.target.value)} />
      <Dropdown.Menu style={{width: "100%", maxHeight: "200px", overflowY: "auto"}}>
        {listItems === undefined ?
          <div style={{width: "100%", textAlign: "center"}}><Spinner animation="border" disabled as="span" style={{marginTop: "50px", marginBottom: "50px"}} /></div> :
          getFilteredDropdownItems(props.value, listItems, props)}
      </Dropdown.Menu>
      <Form.Control.Feedback type="invalid">
        {props.validationError}
      </Form.Control.Feedback>
    </Dropdown>
  );
}

export default SearchDropdown;
