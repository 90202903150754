import Heading from "./Heading";
import { Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable, { ColumnMap } from "./DataTable";
import { ActivePageContext, ApiContext } from "./App";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import EditCompanyNameModal from "./EditCompanyNameModal";

function onRowClick(row, navigate, setActivePage) {
  setActivePage({type: "company", data: row});
  navigate(`/companies/${row['id']}`);
}

export function CompaniesTable(props) {
  const navigate = useNavigate();
  const [, setActivePage] = React.useContext(ActivePageContext);

  return (
    <>
      <DataTable
        columnMap={[
          new ColumnMap(row => <Link className="clickable-cell" to={`/companies/${row.id}`} onClick={e => e.stopPropagation()}>{row.name}</Link>, "Name", "30%", "name"),
          new ColumnMap(row => <>{row.projects?.map(project => <><Badge bg="secondary" size="sm">{project.name}</Badge>{" "}</>)}</>, "Projects", "70%", undefined, {whiteSpace: "nowrap"}),
        ]}
        onDataNeeded={ApiContext.getConstructionCompanies.bind(ApiContext)}
        onRowClick={row => onRowClick(row, navigate, setActivePage)}
        {...props}
        />
    </>
  );
}

function Companies(props) {
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);
  const [, setActivePage] = React.useContext(ActivePageContext);

  return (
    <>
      <EditCompanyNameModal creating show={isCreating} onCancel={() => setIsCreating(false)} onSuccess={data => {setIsCreating(false); onRowClick(data, navigate, setActivePage);}} />
      <Heading text="Construction Companies">
        <Button variant="outline-primary" onClick={() => setIsCreating(true)}>New Company</Button>
      </Heading>
      <CompaniesTable />
    </>
  );
}

export default Companies;
