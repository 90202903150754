import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { ApiContext } from "./App";
import CollectionEditor from "./CollectionEditor";
import DeleteSensorGroupModal from "./DeleteSensorGroupModal";
import SearchDropdown from "./SearchDropdown";

class EditSensorGroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      id: undefined,
      name: "",
      constructionProject: "",
      sensors: [],
      constructionProjectError: undefined,
      sensor: "",
      sensorError: undefined,
      showErrors: !this.props.creating,
      isDeleting: false,
      loading: true
    }
  }

  async fetchConstructionProjects() {
    const response = await ApiContext.getConstructionProjectNames();
    return response.data.map(project => ({name: project.name, key: project}));
  }

  async fetchSensors() {
    const response = await ApiContext.getSensorNames();
    return response.data.map(sensor => ({name: `${sensor.name} (${sensor.vendor_id})`, key: sensor}));
  }

  fillData() {
    if (this.props.creating) {
      this.setState({
        id: undefined,
        name: "",
        constructionProject: undefined,
        sensors: [],
        loading: false
      });
    } else {
      if (this.props.sensorGroup.sensors == null) {
        this.setState({
          id: this.props.sensorGroup.id,
          name: "",
          constructionProject: "",
          sensors: []
        });
        this.fetchData();
      } else {
        this.setState({
          id: this.props.sensorGroup.id,
          name: this.props.sensorGroup.name,
          constructionProject: this.props.sensorGroup.construction_project?.name,
          sensors: this.props.sensorGroup.sensors,
          loading: false
        });
      }
    }
    this.setState({
      ready: true,
      constructionProjectError: undefined,
      sensor: "",
      sensorError: undefined,
      showErrors: !this.props.creating
    });
  }

  async fetchData() {
    this.setState({
      ready: false,
      loading: true
    });

    const response = await ApiContext.getSensorGroup(this.props.sensorGroup.id);
    
    if (response.data.id === this.props.sensorGroup.id) {
      this.setState({
        ready: true,
        loading: false,
        id: this.props.sensorGroup.id,
        name: response.data.name,
        constructionProject: response.data.construction_project?.name,
        sensors: response.data.sensors
      });
    }
  }

  async submit() {
    this.setState({showErrors: true});

    if (!this.state.name) {
      return false;
    }

    this.setState({ready: false, constructionProjectError: undefined, sensorError: undefined});
    
    var constructionProjectId;
    if (this.state.constructionProject) {
      const projects = await ApiContext.getConstructionProjectNames();
      constructionProjectId = projects.data.find(project => project.name === this.state.constructionProject)?.id;
      if (constructionProjectId === undefined) {
        this.setState({ready: true, constructionProjectError: "No construction project exists with this name"});
        return false;
      }
    } else {
      constructionProjectId = null;
    }

    const data = {
      name: this.state.name,
      construction_project_id: constructionProjectId,
      sensors: this.state.sensors.map(sensor => sensor.id)
    };

    var response;
    if (this.props.creating) {
      response = await ApiContext.createSensorGroup(data);
    } else {
      response = await ApiContext.updateSensorGroup(this.state.id, data);
    }

    this.props.onSuccess(response.data);
    return true;
  }

  cancel() {
    if (this.state.ready) {
      this.setState({
        isDeleting: false
      });
      this.props.onCancel();
    }
  }

  onDeleteSuccess(data) {
    this.setState({
      isDeleting: false
    });
    this.props.onSuccess(data);
  }

  render() {

    console.log(this.state);
    return (
      <>
        <DeleteSensorGroupModal
          show={this.props.show && this.state.isDeleting}
          sensorGroup={this.props.sensorGroup}
          onCancel={() => this.cancel()}
          onSuccess={data => this.onDeleteSuccess(data)}
        />

        <Modal {...this.props} centered onEnter={() => this.fillData()} onHide={() => this.cancel()} size="lg" show={this.props.show && !this.state.isDeleting}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.creating ? <>New Sensor Group</> : <>Edit {this.props.sensorGroup.name}</>}
            </Modal.Title>
          </Modal.Header>

          {
            this.state.loading ?
            <Modal.Body style={{textAlign: "center"}}>
              <Spinner animation="border" size="lg" style={{width: "75px", height: "75px", marginTop: "100px", marginBottom: "100px"}} as="span" />
            </Modal.Body>
            :
            <Form autoComplete="off">
              <Modal.Body>
                <Form.Group className="mb-3" controlId="sensorName">
                  <Form.Label>Sensor group name</Form.Label>
                  <Form.Control type="text" disabled={!this.state.ready} autoFocus required value={this.state.name} onChange={e => this.setState({name: e.target.value})} isInvalid={this.state.showErrors && !this.state.name} />
                  <Form.Control.Feedback type="invalid">
                    Sensor group name cannot be empty
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Construction project</Form.Label>
                  <SearchDropdown
                    disabled={!this.state.ready}
                    onChange={project => this.setState({constructionProject: project})}
                    value={this.state.constructionProject}
                    placeholder="Not in a project"
                    validationError={this.state.constructionProjectError}
                    onUpdateItems={this.fetchConstructionProjects} />
                </Form.Group>

                <Form.Group className="mb-2">
                  <Form.Label>Sensors</Form.Label>
                  <CollectionEditor
                    disabled={!this.state.ready}
                    dropdownPlaceholder="Add sensor"
                    itemsPlaceholder="No sensors added to the sensor group."
                    validationError={this.state.sensorError}
                    items={this.state.sensors}
                    onUpdateItems={sensors => this.setState({sensors: sensors})}
                    onFetchItems={() => this.fetchSensors()}
                    />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                {!this.props.creating &&
                  <Button variant="danger" disabled={!this.state.ready} onClick={() => this.setState({isDeleting: true})} style={{marginRight: "auto"}}>Delete sensor group</Button>
                }
                <Button variant="secondary" disabled={!this.state.ready} onClick={() => this.props.onCancel()}>Discard</Button>
                <Button variant="primary" type="submit" disabled={!this.state.ready} onClick={() => this.submit()}>
                  {!this.state.ready && <><Spinner as="span" animation="border" size="sm" /> </>}
                  <span>{this.props.creating ? "Create sensor group" : "Save changes"}</span>
                </Button>
              </Modal.Footer>
            </Form>
          }
        </Modal>
      </>
    );
  }
}

export default EditSensorGroupModal;
