class ArrayDataProvider {
  constructor(data) {
    this.data = data;
    this.onDataNeeded = this.onDataNeeded.bind(this);
  }

  onDataNeeded(limit, offset, sortBy=undefined, sortDesc=false) {
    const sorted = [...this.data];
    if (sortBy) {
      sorted.sort((a, b) => a[sortBy]===b[sortBy] ? 0 : a[sortBy]>b[sortBy] ? 1 : -1);
    }
    if (sortDesc) {
      sorted.reverse();
    }

    const totalCount = sorted.length;
    const slice = sorted.slice(offset, offset+limit);

    return {data: slice, count: totalCount};
  }
}

export default ArrayDataProvider;
