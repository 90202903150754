import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import React from 'react';
import { ApiContext } from './App';

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      error: null,
      email: "",
      password: ""
    };
  }

  async onSubmit() {
    this.setState({ready: false, error: null});
    const response = await ApiContext.login(this.state.email, this.state.password);
    if (response.error) {
      this.setState({ready: true, error: response.error});
    } else {
      this.setState({ready: true});
      const user = {
        id: response.data.id,
        name: response.data.name ? response.data.name : response.data.email,
        token: response.token,
        expiry: response.expiry_time
      };
      this.props.onSuccess(user);
    }
  }

  render() {
    return (
      <Modal {...this.props} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Log in to Brickeye Sensors Dashboard
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            {this.state.error && <Alert variant="danger">{this.state.error}</Alert>}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" disabled={!this.state.ready} value={this.state.email} onChange={e => this.setState({email: e.target.value})} />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Enter password" disabled={!this.state.ready} value={this.state.password} onChange={e => this.setState({password: e.target.value})} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" onClick={() => this.onSubmit()} className="w-100" disabled={!this.state.ready}>
              {!this.state.ready && <><Spinner as="span" animation="border" size="sm" /> </>}
              <span>Log in</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default LoginModal;
