import Heading from "./Heading";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable, { ColumnMap } from "./DataTable";
import { ActivePageContext, ApiContext } from "./App";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import EditUserModal from "./EditUserModal";

function onRowClick(row, navigate, setActivePage) {
  setActivePage({type: "user", data: row});
  navigate(`/users/${row['id']}`);
}

export function UsersTable(props) {
  const navigate = useNavigate();
  const [, setActivePage] = React.useContext(ActivePageContext);

  return (
    <DataTable
      columnMap={[
        new ColumnMap(row => <Link className="clickable-cell" to={`/users/${row.id}`} onClick={e => e.stopPropagation()}>{row.name}</Link>, "Name", "20%", "name"),
        new ColumnMap(row => row.title, "Title", "20%", "title"),
        new ColumnMap(row => row.email_address, "Email", "20%", "email_address"),
        new ColumnMap(row => row.construction_company ? 
          <Link className="clickable-cell-light" to={`/companies/${row.construction_company.id}`} onClick={e => {e.stopPropagation(); setActivePage({type: "company", data: row.construction_company});}}>{row.construction_company.name}</Link>
          : "Not in a company", "Company", "20%", "construction_company_id"),
          new ColumnMap(row => row.email_preference ? "Yes" : "No", "Subscribed to emails", "20%", "email_preference"),
      ]}
      onDataNeeded={ApiContext.getUsers.bind(ApiContext)}
      onRowClick={row => onRowClick(row, navigate, setActivePage)}
      {...props}
      />
  );
}

function Users(props) {
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);
  const [, setActivePage] = React.useContext(ActivePageContext);

  return (
    <>
      <EditUserModal show={isCreating} creating onCancel={() => setIsCreating(false)} onSuccess={user => {setIsCreating(false); onRowClick(user, navigate, setActivePage);}} />
      <Heading text="Users">
        <Button variant="outline-primary" onClick={() => setIsCreating(true)}>New User</Button>
      </Heading>
      <UsersTable />
    </>
  );
}

export default Users;
