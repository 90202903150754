import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { ApiContext } from "./App";
import SearchDropdown from "./SearchDropdown";

class EditSensorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      id: undefined,
      name: "",
      location: "",
      suppressSummary: false,
      sensorGroup: "",
      sensorGroupError: undefined
    }
  }

  async fetchSensorGroups() {
    const response = await ApiContext.getSensorGroupNames();
    return response.data.map(sensorGroup => ({name: sensorGroup.name, key: sensorGroup}));
  }

  fillData() {
    this.setState({
      id: this.props.sensor.id,
      name: this.props.sensor.name,
      location: this.props.sensor.location,
      suppressSummary: this.props.sensor.suppress_summary,
      sensorGroup: this.props.sensor.sensor_group?.name,
      ready: true,
      sensorGroupError: undefined
    });
  }

  async submit() {
    if (!this.state.name) {
      return false;
    }

    this.setState({ready: false, sensorGroupError: undefined});
    
    var sensorGroupId;
    if (this.state.sensorGroup) {
      const sensorGroups = await ApiContext.getSensorGroupNames();
      sensorGroupId = sensorGroups.data.find(sensorGroup => sensorGroup.name === this.state.sensorGroup)?.id;
      if (sensorGroupId === undefined) {
        this.setState({ready: true, sensorGroupError: "No sensor group exists with this name"});
        return false;
      }
    } else {
      sensorGroupId = null;
    }

    const response = await ApiContext.updateSensor(this.state.id, {
      name: this.state.name,
      location: this.state.location,
      suppress_summary: this.state.suppressSummary,
      sensor_group_id: sensorGroupId
    });

    this.props.onSuccess(response.data);
    return true;
  }

  cancel() {
    if (this.state.ready) {
      this.props.onCancel();
    }
  }

  render() {
    return (
      <Modal {...this.props} centered onEnter={() => this.fillData()} onHide={() => this.cancel()}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit {this.props.sensor.name}
          </Modal.Title>
        </Modal.Header>

        <Form autoComplete="off">
          <Modal.Body>
            <Form.Group className="mb-3" controlId="sensorName">
              <Form.Label>Sensor name</Form.Label>
              <Form.Control type="text" disabled={!this.state.ready} autoFocus required value={this.state.name} onChange={e => this.setState({name: e.target.value})} isInvalid={!this.state.name} />
              <Form.Control.Feedback type="invalid">
                Sensor name cannot be empty
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Control type="text" disabled={!this.state.ready} value={this.state.location} onChange={e => this.setState({location: e.target.value})} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Sensor group</Form.Label>
              <SearchDropdown
                disabled={!this.state.ready}
                onChange={sensorGroup => this.setState({sensorGroup: sensorGroup})}
                value={this.state.sensorGroup}
                placeholder="Not in a sensor group"
                validationError={this.state.sensorGroupError}
                onUpdateItems={this.fetchSensorGroups} />
            </Form.Group>

            <Form.Group className="mb-2" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" disabled={!this.state.ready} label="Suppress summary" checked={this.state.suppressSummary} onChange={e => this.setState({suppressSummary: e.target.checked})} />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" disabled={!this.state.ready} onClick={() => this.props.onCancel()}>Discard</Button>
            <Button variant="primary" type="submit" disabled={!this.state.ready} onClick={() => this.submit()}>
              {!this.state.ready && <><Spinner as="span" animation="border" size="sm" /> </>}
              <span>Save changes</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default EditSensorModal;
