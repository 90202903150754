import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { ApiContext } from "./App";
import CollectionEditor from "./CollectionEditor";

class EditProjectUsersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      id: undefined,
      users: []
    }
  }

  async fetchUsers() {
    const response = await ApiContext.getUserNames();
    return response.data.map(user => ({name: `${user.name} (${user.email_address})`, key: user}));
  }

  fillData() {
    this.setState({
      id: this.props.project.id,
      users: this.props.project.users,
      ready: true
    });
  }

  async submit() {
    this.setState({ready: false});

    const response = await ApiContext.updateConstructionProject(this.state.id, {
      users: this.state.users.map(user => user.id)
    });

    this.props.onSuccess(response.data);
    return true;
  }

  cancel() {
    if (this.state.ready) {
      this.props.onCancel();
    }
  }

  render() {
    return (
      <Modal {...this.props} centered onEnter={() => this.fillData()} onHide={() => this.cancel()}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit {this.props.project.name}
          </Modal.Title>
        </Modal.Header>

        <Form autoComplete="off">
          <Modal.Body>
            <Form.Group className="mb-2" controlId="companyName">
              <Form.Label>Users in project</Form.Label>
              <CollectionEditor
                disabled={!this.state.ready}
                dropdownPlaceholder="Add user"
                itemsPlaceholder="No users linked with this project."
                items={this.state.users}
                onUpdateItems={users => this.setState({users: users})}
                onFetchItems={() => this.fetchUsers()}
                getDisplayName={user => user.name ? user.name : user.email_address}
                />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" disabled={!this.state.ready} onClick={() => this.props.onCancel()}>Discard</Button>
            <Button variant="primary" type="submit" disabled={!this.state.ready} onClick={() => this.submit()}>
              {!this.state.ready && <><Spinner as="span" animation="border" size="sm" /> </>}
              <span>Save changes</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default EditProjectUsersModal;
