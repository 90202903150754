import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { ApiContext } from "./App";
import SearchDropdown from "./SearchDropdown";

class EditUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      id: undefined,
      name: "",
      title: "",
      emailAddress: "",
      emailPreference: true,
      constructionCompany: "",
      constructionCompanyError: undefined,
      showErrors: !this.props.creating
    }
  }

  async fetchConstructionCompanies() {
    const response = await ApiContext.getConstructionCompanyNames();
    return response.data.map(project => ({name: project.name, key: project}));
  }

  fillData() {
    if (this.props.creating) {
      this.setState({
        id: undefined,
        name: "",
        title: "",
        emailAddress: "",
        emailPreference: true,
        constructionCompany: ""
      });
    } else {
      this.setState({
        id: this.props.user.id,
        name: this.props.user.name,
        title: this.props.user.title,
        emailAddress: this.props.user.email_address,
        emailPreference: this.props.user.email_preference,
        constructionCompany: this.props.user.construction_company?.name,
      });
    }
    this.setState({
      ready: true,
      constructionCompanyError: undefined,
      showErrors: !this.props.creating
    });
  }

  async submit() {
    this.setState({showErrors: true});

    if (!this.state.emailAddress) {
      return false;
    }

    this.setState({ready: false, constructionCompanyError: undefined});
    
    var constructionCompanyId;
    if (this.state.constructionCompany) {
      const companies = await ApiContext.getConstructionCompanyNames();
      constructionCompanyId = companies.data.find(company => company.name === this.state.constructionCompany)?.id;
      if (constructionCompanyId === undefined) {
        this.setState({ready: true, constructionCompanyError: "No construction project exists with this name"});
        return false;
      }
    } else {
      constructionCompanyId = null;
    }

    const data = {
      name: this.state.name,
      title: this.state.title,
      email_address: this.state.emailAddress,
      email_preference: this.state.emailPreference,
      construction_company_id: constructionCompanyId
    };

    var response;
    if (this.props.creating) {
      response = await ApiContext.createUser(data);
    } else {
      response = await ApiContext.updateUser(this.state.id, data);
    }

    this.props.onSuccess(response.data);
    return true;
  }

  cancel() {
    if (this.state.ready) {
      this.props.onCancel();
    }
  }

  render() {
    return (
      <>
        <Modal {...this.props} centered onEnter={() => this.fillData()} onHide={() => this.cancel()} show={this.props.show}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.creating ? <>New User</> : <>Edit {this.props.user.name}</>}
            </Modal.Title>
          </Modal.Header>

          <Form autoComplete="off">
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" disabled={!this.state.ready} autoFocus value={this.state.name} onChange={e => this.setState({name: e.target.value})} />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" disabled={!this.state.ready} value={this.state.title} onChange={e => this.setState({title: e.target.value})} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="text" disabled={!this.state.ready} required value={this.state.emailAddress} onChange={e => this.setState({emailAddress: e.target.value})} isInvalid={this.state.showErrors && !this.state.emailAddress} />
                <Form.Control.Feedback type="invalid">
                  Email address cannot be empty
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Construction company</Form.Label>
                <SearchDropdown
                  disabled={!this.state.ready}
                  onChange={company => this.setState({constructionCompany: company})}
                  value={this.state.constructionCompany}
                  placeholder="Not in a company"
                  validationError={this.state.constructionCompanyError}
                  onUpdateItems={this.fetchConstructionCompanies} />
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Check type="checkbox" disabled={!this.state.ready} label="Subscribe to emails" checked={this.state.emailPreference} onChange={e => this.setState({emailPreference: e.target.checked})} />
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Row className="w-100">
                <Col>
                </Col>
                <Col>
                </Col>
              </Row>
                  <Button variant="secondary" disabled={!this.state.ready} onClick={() => this.props.onCancel()}>Discard</Button>
                  <Button variant="primary" type="submit" disabled={!this.state.ready} onClick={() => this.submit()}>
                    {!this.state.ready && <><Spinner as="span" animation="border" size="sm" /> </>}
                    <span>{this.props.creating ? "Create user" : "Save changes"}</span>
                  </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default EditUserModal;
