import { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import SearchDropdown from "./SearchDropdown";

function CollectionEditor(props) {
  const [input, setInput] = useState("");
  const [items, setItems] = useState(props.items);

  useEffect(() => setItems(props.items), [props.items]);
  
  function addItem(item) {
    setInput("");

    if (!items.find(currentItem => currentItem.id === item.id)) {
      const newItems = [...items, item];
      props.onUpdateItems(newItems);
    }
  }

  function removeItem(item) {
    const newItems = items.filter(currentItem => currentItem.id !== item.id);
    props.onUpdateItems(newItems);
  }

  return (
    <>
      <Row className="mb-2">
        <Col>
          <SearchDropdown
            disabled={props.disabled}
            onChange={input => setInput(input)}
            onSelect={item => addItem(item)}
            value={input}
            placeholder={props.dropdownPlaceholder}
            validationError={props.validationError}
            onUpdateItems={() => props.onFetchItems()} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-control" style={{paddingTop: "10px", height: "150px", maxHeight: "150px", overflowY: "auto"}}>
            {items.map(item => <span key={item.id}>
              <ButtonGroup size="sm" style={{marginRight: "10px", marginBottom: "5px"}}>
                <Button variant="outline-secondary" disabled style={{color: "black"}}>{props.getDisplayName ? props.getDisplayName(item) : item.name}</Button>
                <Button variant="outline-danger" onClick={() => removeItem(item)} style={{lineHeight: 0}}><X /></Button>
              </ButtonGroup>
            </span>)}
            {items.length === 0 && <div style={{marginBottom: "5px", color: "#6c757d"}}>{props.itemsPlaceholder}</div>}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default CollectionEditor;
