import React from "react";
import { ApiContext } from "./App";
import ConfirmModal from "./ConfirmModal";

class DeleteSensorGroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      sensorGroup: undefined
    }
  }

  fillData() {
    this.setState({
      ready: true,
      sensorGroup: this.props.sensorGroup
    });
  }

  async onConfirm() {
    if (!this.state.sensorGroup) {
      return;
    }

    this.setState({
      ready: false
    });

    const response = await ApiContext.deleteSensorGroup(this.state.sensorGroup.id);

    this.props.onSuccess(response.data);
  }

  render() {
    return (
      <ConfirmModal {...this.props}
        ready={this.state.ready}
        onEnter={() => this.fillData()}
        title={`Delete ${this.state.sensorGroup?.name}`}
        text={`Are you sure you want to delete sensor group ${this.state.sensorGroup?.name}?`}
        yesText="Yes, delete" yesVariant="danger" noText="No, cancel"
        onConfirm={() => this.onConfirm()}
        />
    );
  }
}

export default DeleteSensorGroupModal;
