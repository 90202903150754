import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ActivePageContext, ApiContext } from "./App";
import ArrayDataProvider from "./ArrayDataProvider";
import DataTable, { ColumnMap } from "./DataTable";
import EmailEventModal from "./EmailEventModal";
import FullPageSpinner from "./FullPageSpinner";
import Heading from "./Heading";
import ReportsTable from "./ReportsTable";

async function fetchEmail(id, setEmail, setActivePage) {
  const response = await ApiContext.getEmail(id);
  setEmail(response.data);
  setActivePage({type: "email", data: response.data});
}

function EventsTable(props) {
  const [eventShown, setEventShown] = useState(undefined);
  const [event, setEvent] = useState(undefined);

  return (
    <>
      <EmailEventModal event={event} show={eventShown} onHide={() => setEventShown(false)} />
      <DataTable
        columnMap={[
          new ColumnMap(row => new Date(row.time*1000).toLocaleString(), "Time", "20%", "time"),
          new ColumnMap(row => props.email.recipient, "Recipient", "20%", "recipient"),
          new ColumnMap(row => row.type, "Event Type", "20%", "type"),
          new ColumnMap(row => row.details, "Details", "40%", "details"),
        ]}
        onDataNeeded={new ArrayDataProvider(props.email.events).onDataNeeded}
        onRowClick={row => {setEvent({...row, email: props.email}); setEventShown(true);}}
        defaultOrder="time" defaultOrderDesc
        {...props}
        />
      </>
  );
}

function EmailDetails() {
  const params = useParams();
  const [email, setEmail] = useState(undefined);
  const [, setActivePage] = React.useContext(ActivePageContext);

  if (!email) {
    fetchEmail(params.id, setEmail, setActivePage);
  }

  return (
    <>
      {email ?
      <>
        <Heading text="Notification Email Details" />
        <div className="details-grid">
          <Row>
            <Col md={4}>
              <div className="details-title">Recipient (at time of sending)</div>
              <div>{email.recipient}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">User</div>
              <div><Link className="clickable-cell-light" style={{textDecoration: "underline"}} to={`/users/${email.user?.id}`}>{email.user?.name ? email.user?.name : email.user?.email_address}</Link></div>
            </Col>
            <Col md={4}>
              <div className="details-title">Construction project</div>
              <div><Link className="clickable-cell-light" style={{textDecoration: "underline"}} to={`/users/${email.construction_project?.id}`}>{email.construction_project?.name}</Link></div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="details-title">SES message ID</div>
              <div>{email.ses_message_id}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Email token</div>
              <div>{email.token}</div>
            </Col>
            <Col md={4}>
              <div className="details-title">Sending address</div>
              <div>{email.sending_address}</div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="details-title">Batch ID</div>
              <div><Link className="clickable-cell-light" style={{textDecoration: "underline"}} to={`/batches/${email.batch_id}`}>{email.batch_id}</Link></div>
            </Col>
            <Col md={4}>
              <div className="details-title">Creation time</div>
              <div>{new Date(email.creation_time*1000).toLocaleString()}</div>
            </Col>
          </Row>
        </div>
        <Heading text="Reports in email" small style={{marginTop: "10px"}} />
        <ReportsTable noCalendar rowsPerPage={10} onDataNeeded={new ArrayDataProvider(email.reports).onDataNeeded} />
        <Heading text="Events" small style={{marginTop: "10px"}} />
        <EventsTable email={email} />
      </>
      :
      <FullPageSpinner />
      }
    </>
  );
}

export default EmailDetails;
