import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ActivePageContext, ApiContext } from "./App";
import DataTable, { ColumnMap } from "./DataTable";
import EmailEventModal from "./EmailEventModal";
import EmailsTable from "./EmailsTable";
import Heading from "./Heading";

async function onDataNeeded(batchId, setActivePage, limit, offset, sortBy, sortDesc, filters) {
  const response = await ApiContext.getEmails(limit, offset, sortBy, sortDesc, filters);

  if (response.data.length > 0) {
    setActivePage({type: "batch", data: {id: batchId, construction_project: response.data[0].construction_project}});
  }

  return response;
}

export function EventsTable(props) {
  const [eventShown, setEventShown] = useState(undefined);
  const [event, setEvent] = useState(undefined);

  return (
    <>
      <EmailEventModal event={event} show={eventShown} onHide={() => setEventShown(false)} />
      <DataTable
        columnMap={[
          new ColumnMap(row => new Date(row.time*1000).toLocaleString(), "Time", "20%", "time"),
          new ColumnMap(row => row.email?.recipient, "Recipient", "20%", "token"),
          new ColumnMap(row => row.type, "Event Type", "20%", "type"),
          new ColumnMap(row => row.details, "Details", props.showBatchId ? "20%" : "40%", "details"),
          ...(props.showBatchId ? [new ColumnMap(row => <Link className="clickable-cell-light" to={`/batches/${row.email?.batch_id}`} onClick={e => e.stopPropagation()}>{row.email?.batch_id}</Link>, "Batch ID", "20%")] : []),
        ]}
        onDataNeeded={ApiContext.getEmailEvents.bind(ApiContext)}
        onRowClick={row => {setEvent(row); setEventShown(true);}}
        defaultOrder="time" defaultOrderDesc
        {...props}
        />
    </>
  );
}

function BatchDetails(props) {
  const params = useParams();
  const [, setActivePage] = React.useContext(ActivePageContext);

  return (
    <>
      <>
        <Heading text={`Batch Details: ${params.id}`} />
        <Heading text="Emails in batch" small style={{marginTop: "10px"}} />
        <EmailsTable noCalendar rowsPerPage={10} onDataNeeded={(limit, offset, sortBy, sortDesc, filters) => onDataNeeded(params.id, setActivePage, limit, offset, sortBy, sortDesc, filters)} batch={params.id} />
        <Heading text="Events" small style={{marginTop: "10px"}} />
        <EventsTable rowsPerPage={10} filter={{"filter_NotificationEmail.batch_id": params.id}} />
      </>
    </>
  );
}

export default BatchDetails;
