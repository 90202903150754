import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { ApiContext, UserContext } from "./App";
import DataTable, { ColumnMap } from "./DataTable";
import EditSystemUserModal from "./EditSystemUserModal";
import Heading from "./Heading";

function SystemUsers(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [editedUser, setEditedUser] = useState(undefined);
  const dataTable = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loggedInUser,] = useContext(UserContext);

  useEffect(() => {
    if (searchParams.get("goto")) {
      setEditedUser({id: Number(searchParams.get("goto"))});
      setIsEditing(true);
    }
  }, [searchParams]);

  return (
    <>
      <Heading text="System Users">
        <Button variant="outline-primary" onClick={() => setIsCreating(true)}>New System User</Button>
      </Heading>
      {editedUser &&
        <EditSystemUserModal show={isEditing} user={editedUser} loggedInUser={loggedInUser} onCancel={() => {setIsEditing(false); searchParams.delete("goto"); setSearchParams(searchParams);}} onSuccess={() => setIsEditing(false) || dataTable.current.reload()} />
      }
      <EditSystemUserModal show={isCreating} loggedInUser={loggedInUser} creating onCancel={() => setIsCreating(false)} onSuccess={() => setIsCreating(false) || dataTable.current.reload()} />
      <DataTable
        ref={dataTable}
        columnMap={[
          new ColumnMap(row => <a className="clickable-cell">{row.email}</a>, "Email", "25%", "email"),
          new ColumnMap(row => row.name, "Name", "75%", "name"),
        ]}
        onDataNeeded={ApiContext.getSystemUsers.bind(ApiContext)}
        onRowClick={row => setEditedUser(row) || setIsEditing(true)}
        />
    </>
  );
}

export default SystemUsers;
