import React from "react";
import { Container, Nav, Dropdown } from "react-bootstrap";
import logoDark from "./logo-dark.png";
import { NavLink, Link } from "react-router-dom";
import "./Sidebar.css";
import { UserContext, ActivePageContext } from "../App";

function Sidebar(props) {
  const [user, setUser] = React.useContext(UserContext);
  const [activePage, setActivePage] = React.useContext(ActivePageContext);

  return (
    <Container className="h-100 d-flex flex-column" id="sidebar">
      <img src={logoDark} id="top-logo" alt="Logo" />
      <hr />
      <Nav variant="pills">
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/" onClick={() => setActivePage({})}>Home</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/sensors" onClick={() => setActivePage({})}>Sensors</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/sensor-groups" className="subitem" onClick={() => setActivePage({})}>Sensor Groups</Nav.Link>
        </Nav.Item>
        {activePage.type === "sensor" && activePage.data &&
          <Nav.Item>
            <Nav.Link as={NavLink} to={`/sensors/${activePage.data.id}`} className="subitem">{activePage.data.name}</Nav.Link>
          </Nav.Item>
        }
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/companies" onClick={() => setActivePage({})}>Companies</Nav.Link>
        </Nav.Item>
        {activePage.type === "company" && activePage.data &&
          <Nav.Item>
            <Nav.Link as={NavLink} to={`/companies/${activePage.data.id}`} className="subitem">{activePage.data.name}</Nav.Link>
          </Nav.Item>
        }
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/projects" onClick={() => setActivePage({})}>Projects</Nav.Link>
        </Nav.Item>
        {activePage.type === "project" && activePage.data &&
          <Nav.Item>
            <Nav.Link as={NavLink} to={`/projects/${activePage.data.id}`} className="subitem">{activePage.data.name}</Nav.Link>
          </Nav.Item>
        }
        {(activePage.type === "report" || activePage.type === "email" || activePage.type === "batch") && activePage.data &&
          <>
            <Nav.Item>
              <Nav.Link as={NavLink} to={`/projects/${activePage.data.construction_project?.id}`} className="subitem subitem-mid">{activePage.data.construction_project?.name}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              {activePage.type === "report" && <Nav.Link as={NavLink} to={`/reports/${activePage.data.id}`} className="subitem subsubitem">{activePage.data.file}</Nav.Link>}
              {activePage.type === "email" && <Nav.Link as={NavLink} to={`/emails/${activePage.data.id}`} className="subitem subsubitem">Email to {activePage.data.recipient}</Nav.Link>}
              {activePage.type === "batch" && <Nav.Link as={NavLink} to={`/batches/${activePage.data.id}`} className="subitem subsubitem">{activePage.data.id}</Nav.Link>}
            </Nav.Item>
          </>
        }
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/users" onClick={() => setActivePage({})}>Users</Nav.Link>
        </Nav.Item>
        {activePage.type === "user" && activePage.data &&
          <Nav.Item>
            <Nav.Link as={NavLink} to={`/users/${activePage.data.id}`} className="subitem">{activePage.data.name ? activePage.data.name : activePage.data.email_address}</Nav.Link>
          </Nav.Item>
        }
      </Nav>
      <hr />
      <Nav variant="pills">
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/system-users" onClick={() => setActivePage({})}>System Users</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/log" onClick={() => setActivePage({})}>Event Log</Nav.Link>
        </Nav.Item>
      </Nav>
      <hr className="mt-auto" />
      <div className="px-2">
        {user ?
          <Dropdown as="span">
            <Dropdown.Toggle as="span" className="name-toggle">Logged in as <span className="name">{user.name}</span></Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={`/system-users?goto=${user.id}`}>User details</Dropdown.Item>
              <Dropdown.Item as={Link} to="/" onClick={() => setUser(null)}>Log out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          :
          <span className="name-toggle">Not logged in</span>
        }
      </div>
    </Container>
  );
}

export default Sidebar;
