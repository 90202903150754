import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function EmailEventModal(props) {
  return (
    <Modal centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Email Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.event && <div className="details-grid-vertical">
          <Row>
            <Col xs={3}>In email</Col>
            <Col><Link className="clickable-cell-light" style={{textDecoration: "underline"}} to={`/emails/${props.event.email?.id}`} onClick={() => props.onHide()}>Email to {props.event.email?.recipient}</Link></Col>
          </Row>
          <Row>
            <Col xs={3}>Time</Col>
            <Col>{new Date(props.event.time*1000).toLocaleString()}</Col>
          </Row>
          <Row>
            <Col xs={3}>Recipient</Col>
            <Col>{props.event.email?.recipient}</Col>
          </Row>
          <Row>
            <Col xs={3}>Event Type</Col>
            <Col>{props.event.type}</Col>
          </Row>
          <Row>
            <Col xs={3}>Details</Col>
            <Col>{props.event.details ? props.event.details : "n/a"}</Col>
          </Row>
          <Row>
            <Col xs={3}>Origin IP</Col>
            <Col>{props.event.user_ip ? props.event.user_ip : "n/a"}</Col>
          </Row>
          <Row>
            <Col xs={3}>User agent</Col>
            <Col>{props.event.user_agent?.full ? props.event.user_agent?.full : "n/a"}</Col>
          </Row>
        </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button className="w-100" variant="secondary" onClick={() => props.onHide()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EmailEventModal;
