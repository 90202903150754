import React, { useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ActivePageContext, ApiContext } from "./App";
import ArrayDataProvider from "./ArrayDataProvider";
import DeleteCompanyLogoModal from "./DeleteCompanyLogoModal";
import DeleteCompanyModal from "./DeleteCompanyModal";
import EditCompanyNameModal from "./EditCompanyNameModal";
import EditCompanyProjectsModal from "./EditCompanyProjectsModal";
import EditCompanyUsersModal from "./EditCompanyUsersModal";
import FullPageSpinner from "./FullPageSpinner";
import Heading from "./Heading";
import { ProjectsTable } from "./Projects";
import UploadCompanyLogoModal from "./UploadCompanyLogoModal";
import { UsersTable } from "./Users";

async function fetchCompany(id, setCompany, setActivePage) {
  const response = await ApiContext.getConstructionCompany(id);
  setCompany(response.data);
  setActivePage({type: "company", data: response.data});
}

async function fetchLogo(id, setImage, onFetch) {
  const response = await ApiContext.getConstructionCompanyLogo(id);
  setImage(response.data);
  if (onFetch) {
    onFetch(response.data);
  }
}

function navigateAway(navigate, setActivePage) {
  setActivePage({type: undefined, data: undefined});
  navigate("/companies");
}

function CompanyLogo(props) {
  const [image, setImage] = useState(undefined);

  if (image === undefined) {
    fetchLogo(props.company.id, setImage, props.onFetch);
  }

  return (
    <div style={{width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center"}}>
      {image && <img src={image} style={{objectFit: "scale-down", width: "100%", height: "100%"}} alt="Company logo" />}
      {image === undefined && <Spinner animation="border" as="span" />}
      {image === null && <span>No logo</span>}
    </div>
  );
}

function CompanyDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState(undefined);
  const [editingName, setEditingName] = useState(false);
  const [editingProjects, setEditingProjects] = useState(false);
  const [editingUsers, setEditingUsers] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletingLogo, setDeletingLogo] = useState(false);
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [, setActivePage] = React.useContext(ActivePageContext);
  const [logo, setLogo] = useState(undefined);

  if (!company) {
    if (logo) {
      setLogo(undefined);
    }
    fetchCompany(params.id, setCompany, setActivePage);
  }

  return (
    <>
      {company ?
      <>
        <EditCompanyNameModal show={editingName} company={company} onCancel={() => setEditingName(false)} onSuccess={() => setEditingName(false) || setCompany(undefined)} />
        <EditCompanyProjectsModal show={editingProjects} company={company} onCancel={() => setEditingProjects(false)} onSuccess={() => setEditingProjects(false) || setCompany(undefined)} />
        <EditCompanyUsersModal show={editingUsers} company={company} onCancel={() => setEditingUsers(false)} onSuccess={() => setEditingUsers(false) || setCompany(undefined)} />
        <DeleteCompanyModal show={deleting} company={company} onCancel={() => setDeleting(false)} onSuccess={() => setDeleting(false) || navigateAway(navigate, setActivePage)} />
        <DeleteCompanyLogoModal show={deletingLogo} company={company} onCancel={() => setDeletingLogo(false)} onSuccess={() => setDeletingLogo(false) || setCompany(undefined)} />
        <UploadCompanyLogoModal show={uploadingLogo} company={company} onCancel={() => setUploadingLogo(false)} onSuccess={() => setUploadingLogo(false) || setCompany(undefined)} />
        <Heading text={company ? `Company Details: ${company.name}` : "Company Details"}>
          <Button variant="outline-primary" onClick={() => setEditingName(true)}>Edit Name</Button>
          <Button variant="outline-danger" onClick={() => setDeleting(true)}>Delete Company</Button>
        </Heading>
        <div className="details-grid">
          <Row>
            <Col xs={3}>
              <CompanyLogo company={company} onFetch={logo => setLogo(logo)} />
            </Col>
            <Col>
              <div className="d-grid gap-2" style={{maxWidth: "200px"}}>
                <Button variant="outline-primary" onClick={() => setUploadingLogo(true)}>Upload Logo</Button>
                {logo && <Button variant="outline-danger" onClick={() => setDeletingLogo(true)}>Remove Logo</Button>}
              </div>
            </Col>
          </Row>
        </div>
        <Heading text="Projects" small style={{marginTop: "10px"}}>
          <Button variant="outline-primary" onClick={() => setEditingProjects(true)}>Manage Projects in Company</Button>
        </Heading>
        <ProjectsTable rowsPerPage={10} onDataNeeded={new ArrayDataProvider(company.projects).onDataNeeded} />
        <Heading text="Users" small style={{marginTop: "10px"}}>
          <Button variant="outline-primary" onClick={() => setEditingUsers(true)}>Manage Users in Company</Button>
        </Heading>
        <UsersTable rowsPerPage={10} onDataNeeded={new ArrayDataProvider(company.users).onDataNeeded} />
      </>
      :
      <FullPageSpinner />
      }
    </>
  );
}

export default CompanyDetails;
