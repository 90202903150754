const axios = require('axios');

class Api {
    constructor(urlBase) {
        this.urlBase = urlBase;
        this.token = localStorage.getItem("token");
    }

    async login(email, password) {
        try {
            const authUrl=this.urlBase + '/auth/login';
            console.log("urlBase: " + this.urlBase );
            console.log("authUrl: " + authUrl );
            const response = await axios.post(authUrl, {
                email: email,
                password: password
            });
            this.token = response.data.token;
            return response.data;
        } catch (error) {
            console.error(error)
            return error.response.data;
        }
    }

    async renewToken() {
        try {
            const response = await axios.get(this.urlBase + '/auth/renew', {
                headers: { "X-Auth-Token": this.token }
            });
            this.token = response.data.token;
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getSensors(limit, offset, sortBy=undefined, sortDesc=false, filters={}) {
        try {
            const response = await axios.get(this.urlBase + '/sensor/', {
                params: { limit: limit, offset: offset, order_by: sortBy, order_by_desc: Number(sortDesc), ...filters },
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getSensorNames() {
        try {
            const response = await axios.get(this.urlBase + '/sensor/all', {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getSensor(id) {
        try {
            const response = await axios.get(this.urlBase + '/sensor/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async updateSensor(id, data) {
        try {
            const response = await axios.patch(this.urlBase + '/sensor/' + id, data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getSensorGroups(limit, offset, sortBy=undefined, sortDesc=false, filters={}) {
        try {
            const response = await axios.get(this.urlBase + '/sensor_group/', {
                params: { limit: limit, offset: offset, order_by: sortBy, order_by_desc: Number(sortDesc), ...filters },
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getSensorGroup(id) {
        try {
            const response = await axios.get(this.urlBase + '/sensor_group/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getSensorGroupNames() {
        try {
            const response = await axios.get(this.urlBase + '/sensor_group/all', {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async updateSensorGroup(id, data) {
        try {
            const response = await axios.patch(this.urlBase + '/sensor_group/' + id, data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async deleteSensorGroup(id) {
        try {
            const response = await axios.delete(this.urlBase + '/sensor_group/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async createSensorGroup(data) {
        try {
            const response = await axios.put(this.urlBase + '/sensor_group/', data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getUsers(limit, offset, sortBy=undefined, sortDesc=false, filters={}) {
        try {
            const response = await axios.get(this.urlBase + '/user/', {
                params: { limit: limit, offset: offset, order_by: sortBy, order_by_desc: Number(sortDesc), ...filters },
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getUserNames() {
        try {
            const response = await axios.get(this.urlBase + '/user/all', {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getUser(id) {
        try {
            const response = await axios.get(this.urlBase + '/user/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async createUser(data) {
        try {
            const response = await axios.put(this.urlBase + '/user/', data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async updateUser(id, data) {
        try {
            const response = await axios.patch(this.urlBase + '/user/' + id, data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async deleteUser(id) {
        try {
            const response = await axios.delete(this.urlBase + '/user/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getConstructionProjects(limit, offset, sortBy=undefined, sortDesc=false, filters={}) {
        try {
            const response = await axios.get(this.urlBase + '/construction_project/', {
                params: { limit: limit, offset: offset, order_by: sortBy, order_by_desc: Number(sortDesc), ...filters },
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getConstructionProjectNames() {
        try {
            const response = await axios.get(this.urlBase + '/construction_project/all', {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getConstructionProject(id) {
        try {
            const response = await axios.get(this.urlBase + '/construction_project/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getTimezones() {
        try {
            const response = await axios.get(this.urlBase + '/construction_project/timezones', {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async updateConstructionProject(id, data) {
        try {
            const response = await axios.patch(this.urlBase + '/construction_project/' + id, data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async createConstructionProject(id, data) {
        try {
            const response = await axios.put(this.urlBase + '/construction_project/', data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async deleteConstructionProject(id) {
        try {
            const response = await axios.delete(this.urlBase + '/construction_project/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    // async getEmailEvents(limit, offset, sortBy=undefined, sortDesc=false, filters={}) {
    //     try {
    //         const response = await axios.get(this.urlBase + '/report/', {
    //             params: { limit: limit, offset: offset, order_by: sortBy, order_by_desc: Number(sortDesc), ...filters },
    //             headers: { "X-Auth-Token": this.token }
    //         });
    //         return response.data;
    //     } catch (error) {
    //         return error.response.data;
    //     }
    // }

    async getReports(limit, offset, sortBy=undefined, sortDesc=false, filters={}) {
        try {
            const response = await axios.get(this.urlBase + '/report/', {
                params: { limit: limit, offset: offset, order_by: sortBy, order_by_desc: Number(sortDesc), ...filters },
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getReportsRange() {
        try {
            const response = await axios.get(this.urlBase + '/report/range', {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getReport(id) {
        try {
            const response = await axios.get(this.urlBase + '/report/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getReportFile(id) {
        try {
            const response = await axios.get(this.urlBase + '/report/' + id + "/file", {
                headers: { "X-Auth-Token": this.token },
                responseType: "blob"
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getEmails(limit, offset, sortBy=undefined, sortDesc=false, filters={}) {
        try {
            const response = await axios.get(this.urlBase + '/email/', {
                params: { limit: limit, offset: offset, order_by: sortBy, order_by_desc: Number(sortDesc), ...filters },
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getEmail(id) {
        try {
            const response = await axios.get(this.urlBase + '/email/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getEmailEvents(limit, offset, sortBy=undefined, sortDesc=false, filters={}) {
        try {
            const response = await axios.get(this.urlBase + '/email_event/', {
                params: { limit: limit, offset: offset, order_by: sortBy, order_by_desc: Number(sortDesc), ...filters },
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getConstructionCompanies(limit, offset, sortBy=undefined, sortDesc=false, filters={}) {
        try {
            const response = await axios.get(this.urlBase + '/construction_company/', {
                params: { limit: limit, offset: offset, order_by: sortBy, order_by_desc: Number(sortDesc), ...filters },
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getConstructionCompanyNames() {
        try {
            const response = await axios.get(this.urlBase + '/construction_company/all', {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getConstructionCompany(id) {
        try {
            const response = await axios.get(this.urlBase + '/construction_company/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async updateConstructionCompany(id, data) {
        try {
            const response = await axios.patch(this.urlBase + '/construction_company/' + id, data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async createConstructionCompany(data) {
        try {
            const response = await axios.put(this.urlBase + '/construction_company/', data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async deleteConstructionCompany(id) {
        try {
            const response = await axios.delete(this.urlBase + '/construction_company/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getConstructionCompanyLogo(id) {
        try {
            const response = await axios.get(this.urlBase + '/construction_company/' + id + "/logo", {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async uploadConstructionCompanyLogo(id, data) {
        try {
            const response = await axios.patch(this.urlBase + '/construction_company/' + id + "/logo", data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async deleteConstructionCompanyLogo(id) {
        try {
            const response = await axios.delete(this.urlBase + '/construction_company/' + id + "/logo", {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getSystemUsers(limit, offset, sortBy=undefined, sortDesc=false, filters={}) {
        try {
            const response = await axios.get(this.urlBase + '/system_user/', {
                params: { limit: limit, offset: offset, order_by: sortBy, order_by_desc: Number(sortDesc), ...filters },
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getSystemUser(id) {
        try {
            const response = await axios.get(this.urlBase + '/system_user/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async createSystemUser(data) {
        try {
            const response = await axios.put(this.urlBase + '/system_user/', data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async updateSystemUser(id, data) {
        try {
            const response = await axios.patch(this.urlBase + '/system_user/' + id, data, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async deleteSystemUser(id) {
        try {
            const response = await axios.delete(this.urlBase + '/system_user/' + id, {
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getEventLog(limit, offset, sortBy=undefined, sortDesc=false, filters={}) {
        try {
            const response = await axios.get(this.urlBase + '/event_log/', {
                params: { limit: limit, offset: offset, order_by: sortBy, order_by_desc: Number(sortDesc), ...filters },
                headers: { "X-Auth-Token": this.token }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }
}

export default Api;
