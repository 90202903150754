import React from "react";
import { Table, Spinner, Row, Col, Pagination } from "react-bootstrap";
import { CaretUp, CaretDownFill, CaretUpFill } from "react-bootstrap-icons";

export class ColumnMap {
  constructor(getter, title, width, sortName=undefined, style={}) {
    this.getter = getter;
    this.title = title;
    this.width = width;
    this.sortName = sortName;
    this.style = style;
  }
}

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      data: [],
      fetchedPage: -1,
      totalCount: undefined,
      currentPage: this.props.currentPage,
      tbodyHeight: this.props.compactLoading ? 0 : this.props.rowsPerPage * 40,
      fetchedSortedBy: undefined,
      fetchedSortedDesc: false,
      sortedBy: this.props.defaultOrder,
      sortedDesc: this.props.defaultOrderDesc
    };
    this.tbodyRef = React.createRef(null);
  }

  componentDidMount() {
    this.fetchData();
  }

  reload() {
    this.setState({fetchedPage: -1}, this.fetchData);
  }

  async fetchData() {
    if (this.state.fetchedPage === this.state.currentPage && this.state.fetchedSortedBy === this.state.sortedBy && this.state.fetchedSortedDesc === this.state.sortedDesc) {
      return;
    }

    //Maintain old table height
    this.setState({
      tbodyHeight: this.tbodyRef.current.clientHeight+2,
      ready: false
    });

    const page = this.state.currentPage;
    const sortBy = this.state.sortedBy;
    const sortDesc = this.state.sortedDesc;
    const response = await this.props.onDataNeeded(this.props.rowsPerPage, page * this.props.rowsPerPage, sortBy, sortDesc, this.props.filter);

    this.setState({
      ready: true,
      data: response.data ? response.data : [],
      fetchedPage: page,
      fetchedSortedBy: sortBy,
      fetchedSortedDesc: sortDesc,
      totalCount: response.count,
      tbodyHeight: undefined
    });

    if (this.props.onDataLoaded) {
      this.props.onDataLoaded();
    }
  }

  get numPages() {
    if (!this.state.totalCount) {
      return 0;
    }
    return Math.ceil(this.state.totalCount / this.props.rowsPerPage);
  }

  setPage(i) {
    console.log(i);
    if (this.state.ready && i >= 0 && i < this.numPages) {
      this.setState({
        currentPage: i
      }, this.fetchData);
    }
  }

  setSort(sortBy, sortDesc) {
    if (this.state.sortedBy !== sortBy || this.state.sortedDesc !== sortDesc) {
      this.setState({
        sortedBy: sortBy,
        sortedDesc: sortDesc
      }, this.fetchData);
    }
  }

  sortSymbol(map) {
    if (!map.sortName) {
      return null;
    }

    var symbol;
    var sortDesc;

    if (map.sortName === this.state.sortedBy) {
      if (this.state.sortedDesc) {
        symbol = <CaretDownFill />;
        sortDesc = false;
      } else {
        symbol = <CaretUpFill />;
        sortDesc = true;
      }
    } else {
      symbol = <CaretUp />;
      sortDesc = false;
    }

    return <span style={{cursor: "pointer"}} onClick={() => this.setSort(map.sortName, sortDesc)}>{symbol}</span>
  }

  getPagination() {
    var elements = [];
    var skippingPages = false;
    for (var i = 0; i < this.numPages; i++) {
      if (i === 0 || i === this.numPages-1 || (i >= this.state.currentPage-2 && i <= this.state.currentPage+2)) {
        elements.push(<Pagination.Item key={i} active={this.state.currentPage===i} onClick={(pageI => () => this.setPage(pageI))(i)}>{i+1}</Pagination.Item>);
        skippingPages = false;
      } else {
        if (!skippingPages) {
          elements.push(<Pagination.Ellipsis key={i} disabled />);
        }
        skippingPages = true;
      }
    }
    return elements;
  }

  render() {
    return (
      <>
        <Table striped hover={this.state.ready} style={{tableLayout: "fixed"}}>
          <thead>
            <tr>
              {
                this.props.columnMap.map((map, i) => <th key={i} style={{width: map.width}}>{map.title} {this.sortSymbol(map)}</th>)
              }
            </tr>
          </thead>
          <tbody ref={this.tbodyRef}>
            {
              this.state.ready ?
              this.state.data.map((row, i) => <tr key={i} style={{cursor: this.props.onRowClick ? "pointer" : "inherit"}} onClick={() => this.props.onRowClick && this.props.onRowClick(row)}>
                {this.props.columnMap.map((map, j) => <td key={j} style={{overflow: "hidden", textOverflow: "ellipsis", ...map.style}}>{map.getter(row)}</td>)}
              </tr>)
              :
              <tr>
                <td style={{textAlign: "center", height: this.state.tbodyHeight, verticalAlign: "middle"}} colSpan={this.props.columnMap.length}>
                  <Spinner as="span" animation="border" style={{width: "4rem", height: "4rem"}} />
                </td>
              </tr>
            }
          </tbody>
        </Table>
        <Row style={{visibility: this.state.totalCount == null ? "hidden" : "visible", marginLeft: "5px", marginRight: "5px"}}>
          <Col>Showing <b>{this.state.data.length}</b> of <b>{this.state.totalCount}</b> records</Col>
          <Col xs={"auto"}>
            <Pagination>
              <Pagination.First onClick={() => this.setPage(0)} />
              <Pagination.Prev onClick={() => this.setPage(this.state.currentPage-1)} />
              {this.getPagination()}
              <Pagination.Next onClick={() => this.setPage(this.state.currentPage+1)} />
              <Pagination.Last onClick={() => this.setPage(this.numPages-1)} />
            </Pagination>
          </Col>
        </Row>
      </>
    );
  }
}

DataTable.defaultProps = {
  'rowsPerPage': 15,
  'currentPage': 0,
  'filter': {}
}

// function withNavigate(Component) {
//   return (props) => (<Component {...props} navigate={useNavigate()} />);
// }

export default DataTable;
