import React from "react";
import { ApiContext } from "./App";
import ConfirmModal from "./ConfirmModal";

class DeleteCompanyLogoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      company: undefined
    }
  }

  fillData() {
    this.setState({
      ready: true,
      company: this.props.company
    });
  }

  async onConfirm() {
    if (!this.state.company) {
      return;
    }

    this.setState({
      ready: false
    });

    const response = await ApiContext.deleteConstructionCompanyLogo(this.state.company.id);

    this.props.onSuccess(response.data);
  }

  render() {
    return (
      <ConfirmModal {...this.props}
        ready={this.state.ready}
        onEnter={() => this.fillData()}
        title={`Remove Company Logo`}
        text={`Do you want to delete the logo associated with construction company ${this.state.company?.name}?`}
        yesText="Yes, delete" yesVariant="danger" noText="No, cancel"
        onConfirm={() => this.onConfirm()}
        />
    );
  }
}

export default DeleteCompanyLogoModal;
